import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Typography, useTheme } from '@mui/material';

import { StyledCheckbox } from '@components/atoms/StyledCheckbox';
import { getApiUrl } from '@config/api';
import { selectToken } from '@services/auth/selectors';

type SubscriptionTermsOfUseBoxProps = {
  termsAccepted: boolean;
  onTermsAcceptedChange: (isAccepted: boolean) => void;
};

export const SubscriptionTermsOfUseBox = ({ termsAccepted, onTermsAcceptedChange }: SubscriptionTermsOfUseBoxProps) => {
  const theme = useTheme();
  const token = useSelector(selectToken);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const revokePdfUrl = () => {
    if (pdfUrl) {
      window.URL.revokeObjectURL(pdfUrl);
    }
  };

  useEffect(() => {
    return () => {
      revokePdfUrl();
    };
  }, [pdfUrl]);

  const handleTermsOfUseClick = async () => {
    revokePdfUrl();

    try {
      const response = await fetch(`${getApiUrl()}/subscriptions/operator-agreement`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `Bearer ${token}`,
        },
      });

      const url = window.URL.createObjectURL(await response.blob());
      setPdfUrl(url);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Failed to fetch operator agreement:', error);
      return;
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      p={2}
      color={theme.palette.darkGray}
      bgcolor={theme.palette.cultured}
      borderRadius={2}
    >
      <StyledCheckbox checked={termsAccepted} onChange={(e, checked) => onTermsAcceptedChange(checked)} />
      <Typography variant="p14">
        <Trans
          i18nKey="byPlacingThisOrderIAgreeToTheTermsOfUseAndPrivacyNotice"
          defaults="By placing this order, I agree to the <1><3>Terms of Use</3></1> and <1><2>Privacy Notice</2></1>."
          components={{
            1: (
              <Typography
                variant="p14"
                color={theme.palette.mediumVermilion}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              />
            ),
            2: (
              <a
                href="https://www.vool.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
                aria-label="Link to VOOL Privacy Policy"
              />
            ),
            3: <Typography display="inline" variant="inherit" onClick={handleTermsOfUseClick} />,
          }}
        />
      </Typography>
    </Box>
  );
};
