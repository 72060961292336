import { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import { max, maxBy } from 'lodash';
import moment from 'moment';

import { CardItem } from '@components/basicWrappers/CardItem';
import { chartDataParsers } from '@services/transactions/chartDataParser';

export const CHART_COLORS = ['#EB4E20', '#386957', '#407090', '#5E5E5E'];

const GRAPH_CATEGORIES = {
  today: 'MMM DD HH:mm',
  week: 'MMM DD HH:mm',
  month: 'MMM DD',
  sixMonths: 'MMM DD',
  year: 'YYYY MMM',
};

type EnergySuppliedProps = {
  summarizedTransactions?: {
    name: string;
    data: { updateTime: string; value: number; cost: number; length: number; filler: boolean }[];
  }[];
  filter?: keyof typeof chartDataParsers;
  isError?: boolean;
  isFetching?: boolean;
};

export const EnergySupplied = ({
  summarizedTransactions,
  isError,
  isFetching,
  filter = 'week',
}: EnergySuppliedProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(!summarizedTransactions?.[0].data?.length);

  const maxValue = useMemo(() => {
    const maxByValue = summarizedTransactions?.map((summary) =>
      maxBy(
        summary.data.filter((d) => !d?.filler),
        'value',
      ),
    );
    return max(maxByValue?.map((mbv) => mbv?.value)) || 0;
  }, [summarizedTransactions]);

  const coloredTransactions = useMemo(
    () =>
      summarizedTransactions?.map((summary, index) => ({
        ...summary,
        color: CHART_COLORS[index % CHART_COLORS.length],
      })),
    [summarizedTransactions],
  );

  useEffect(() => {
    if (!isFetching) {
      setLoading(false);
    }
  }, [isFetching]);

  if (!coloredTransactions) {
    return null;
  }

  return (
    <>
      <CardItem
        className={`
          h-full min-h-[300px] rounded-[16px] border-none p-4 pb-0 pl-0 pr-0 pt-5
        `}
        error={isError}
        loading={loading}
      >
        <div className={`w-full`}>
          <Chart
            type="bar"
            width="100%"
            height="300px"
            options={{
              legend: {
                show: true,
                showForSingleSeries: true,
                markers: {
                  width: 8,
                  height: 8,
                  radius: 100,
                  offsetX: -5,
                },
                labels: {
                  colors: ['#999898'],
                },
              },
              responsive: [
                {
                  breakpoint: undefined,
                  options: {},
                },
              ],
              tooltip: {
                y: {
                  formatter: (value) => `${(value || 0).toFixed(2)} kWh`,
                },
              },
              stroke: {
                show: true,
                lineCap: 'butt',
                width: 1,
                dashArray: 0,
              },
              dataLabels: {
                enabled: false,
              },
              chart: {
                id: 'basic-bar',
                zoom: {
                  enabled: false,
                },
                toolbar: {
                  show: false,
                },
                animations: {
                  enabled: true,
                  easing: 'easeout',
                  animateGradually: {
                    enabled: false,
                    delay: 150,
                  },
                  dynamicAnimation: {
                    enabled: true,
                  },
                },
              },
              xaxis: {
                tooltip: {
                  enabled: true,
                },
                categories: (coloredTransactions?.[0]?.data || []).map((transaction) =>
                  moment(transaction.updateTime).format(GRAPH_CATEGORIES[filter]),
                ),
                tickAmount: 4,
                labels: {
                  offsetY: -5,
                  showDuplicates: false,
                  rotate: 0,
                  style: {
                    fontSize: '10',
                    fontFamily: 'Roboto, sans-serif',
                    colors: '#999898',
                  },
                },
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
              },
              yaxis: [
                {
                  seriesName: t('energySupplied', 'Energy supplied'),
                  showAlways: true,
                  max: maxValue < 0.5 ? 1 : undefined,
                  title: {
                    text: 'kWh',
                    rotate: 0,
                    offsetX: -5,
                    style: {
                      color: '#999898',
                    },
                  },
                  decimalsInFloat: 0,
                  labels: {
                    formatter: (number) => number.toFixed(),
                    offsetX: 5,
                    rotate: 0,
                    style: {
                      fontSize: '9',
                      fontFamily: 'Roboto, sans-serif',
                      colors: '#999898',
                    },
                  },
                  axisBorder: {
                    show: false,
                  },
                  tickAmount: filter === 'year' ? 4 : 3,
                },
              ],
              grid: {
                show: true,
                position: 'back',
                borderColor: '#f4f4f4',
                yaxis: {
                  lines: {
                    show: true,
                  },
                },
                xaxis: {
                  lines: {
                    show: false,
                  },
                },
                padding: {
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 10,
                },
              },
              fill: {
                type: 'solid',
                opacity: 1,
              },
              plotOptions: {
                bar: {
                  borderRadius: 4,
                },
              },
            }}
            series={coloredTransactions?.map((summary) => ({
              ...summary,
              data: summary.data.map((d) => d.value || 0),
            }))}
          />
        </div>
      </CardItem>
    </>
  );
};
