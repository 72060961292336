import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReportingTransaction } from 'models/reporting-transaction';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { compareMenuOptions } from '@components/filters';
import { useAppSelector } from '@services/hooks';
import { useGetRfidCardsQuery } from '@services/rfidCards/endpoints';
import { selectRfidCards } from '@services/rfidCards/selectors';
import { selectUsers } from '@services/users/selectors';
import { selectVehicles } from '@services/vehicles/selectors';
import { useGetRfidCardAssigneeData } from '@views/Workspace/RfidCardsTable/useGetRfidCardAssigneeData';

export const useRfidMenu = (transactions?: ReportingTransaction[]) => {
  const { companyUuid } = useParams();
  useGetRfidCardsQuery({ companyUuid });
  const rfidCards = useAppSelector(selectRfidCards);
  const [rfidMenuOptions, setRfidMenuOptions] = useState<StyledMenuItemProps[]>([]);
  const getRfidCardAssigneeData = useGetRfidCardAssigneeData();
  const users = useAppSelector(selectUsers);
  const vehicles = useAppSelector(selectVehicles);

  useEffect(() => {
    setRfidMenuOptions(
      (prevState) =>
        rfidCards
          ?.map((card) => {
            const assignedTo = getRfidCardAssigneeData({ rfidCard: card, users, vehicles });
            const label = card.normalizedIdentifier + (assignedTo.name ? ` (${assignedTo.name})` : '');

            return {
              uuid: card.uuid,
              checked: prevState?.find((option) => option.uuid === card.uuid)?.checked ?? false,
              label,
              resultCount: transactions?.filter((transaction) => transaction.rfidCardUuid === card.uuid).length,
              onClick: () => toggleOptionChecked(card.uuid),
            };
          })
          .sort(compareMenuOptions) ?? [],
    );
  }, [rfidCards, transactions, users, vehicles]);

  const toggleOptionChecked = (selectedOptionUuid: string) => {
    setRfidMenuOptions((prevOptions) =>
      prevOptions
        ?.map((option) => {
          if (option.uuid === selectedOptionUuid) {
            return { ...option, checked: !option.checked };
          }
          return option;
        })
        .sort(compareMenuOptions),
    );
  };

  return { rfidMenuOptions, setRfidMenuOptions };
};
