import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateCompanyDto } from 'models/company';
import { CompanyMainUseCase } from 'models/company.enums';
import * as yup from 'yup';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { FormTextField } from '@components/atoms/FormTextField';
import Select from '@components/atoms/Select';
import { FormAutocomplete } from '@components/atoms/StyledAutocomplete';
import { StyledButton } from '@components/atoms/StyledButton';
import { useNavigateAfterCompanySwitch } from '@hooks/useSwitchToCompany';
import { useForm } from '@hooks/useTypedForm';
import createWorkspaceBackgroundImage from '@images/welcome/create-workspace.jpg';
import { useCreateCompanyMutation } from '@services/companies/endpoints';
import { selectPartnerCompanies } from '@services/companies/selectors';
import { useAppDispatch, useAppSelector } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';

type WorkspaceForm = Omit<CreateCompanyDto, 'partnerCompanyUuid'> & {
  partnerCompany: { label: string; value: string } | null;
};

type CreateWorkspaceModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const useSchema = () => {
  const { t } = useTranslation();
  return yup
    .object()
    .shape({
      name: yup.string().required(t('required*', 'Required*')),
    })
    .required();
};

export const CreateWorkspaceModal = ({ isOpen, onClose }: CreateWorkspaceModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigateAfterCompanySwitch = useNavigateAfterCompanySwitch();
  const partnerCompanies = useAppSelector(selectPartnerCompanies);

  const schema = useSchema();
  const { control, handleSubmit, reset, setValue, watch } = useForm<WorkspaceForm>({
    schema,
    defaultValues: {
      name: '',
      mainUseCase: CompanyMainUseCase.PRIVATE_USER_AT_HOME,
      partnerCompany: null,
    },
  });

  const mainUseCase = watch('mainUseCase');

  const useCauseOptions = [
    { label: t('privateUserAtHome', 'Private user at home'), value: CompanyMainUseCase.PRIVATE_USER_AT_HOME },
    { label: t('apartmentAssociation', 'Apartment association'), value: CompanyMainUseCase.APARTMENT_ASSOCIATION },
    {
      label: t('chargingForCompanyEmployees', 'Charging for company employees'),
      value: CompanyMainUseCase.CHARGING_FOR_COMPANY_EMPLOYEES,
    },
    {
      label: t('providingPublicChargingService', 'Providing public charging service'),
      value: CompanyMainUseCase.PROVIDING_PUBLIC_CHARGING_SERVICE,
    },
    {
      label: t('otherChargingSolutions', 'Other charging solutions'),
      value: CompanyMainUseCase.OTHER_CHARGING_SOLUTIONS,
    },
  ];

  const [createWorkspace, { isLoading }] = useCreateCompanyMutation();

  const submit = async ({ name, mainUseCase, partnerCompany }: WorkspaceForm) => {
    try {
      await createWorkspace({ name: name.trim(), mainUseCase, partnerCompanyUuid: partnerCompany?.value }).unwrap();
      dispatch(addToastMessage({ type: 'success', title: t('workspaceCreated', 'Workspace created') }));
      navigateAfterCompanySwitch();
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('creatingTheWorkspaceFailed', 'Creating the workspace failed'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
    }
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      reset({
        name: '',
        mainUseCase: CompanyMainUseCase.PRIVATE_USER_AT_HOME,
        partnerCompany: null,
      });
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen>
      <Box display="flex" alignItems="center" height={1} p={3} gap={2}>
        <Box display="flex" alignItems="center" justifyContent="center" width={1} height={1} py={7.5}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="start"
            width={1}
            maxWidth="376px"
            gap={3}
          >
            <DialogTitle
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="start"
              variant="h2"
              sx={{
                padding: 0,
                width: 1,
              }}
            >
              {t('createNewWorkspace', 'Create new workspace')}
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', width: 1, gap: 3, padding: 0 }}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="p16b" color="#282828">
                  {t('whatWillIUseVoolPlatformFor?', 'What will I use Vool platform for?')}
                </Typography>
                <Select
                  label={t('mainUseCase', 'Main use case')}
                  options={useCauseOptions}
                  value={mainUseCase}
                  required
                  onChange={(value) => {
                    setValue('mainUseCase', value, { shouldDirty: true });
                  }}
                />
              </Box>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="p16b" color="#282828">
                  {t('general', 'General')}
                </Typography>
                <FormTextField control={control} name="name" label={t('workspaceName', 'Workspace name')} />
                {!!partnerCompanies.length && (
                  <>
                    <FormAutocomplete
                      name="partnerCompany"
                      control={control}
                      label={`${t('partnerCompany', 'Partner company')} (${t('optional', 'Optional').toLowerCase()})`}
                      options={partnerCompanies.map((company) => ({
                        label: company.name,
                        value: company.uuid,
                      }))}
                    />
                    <Typography
                      variant="p14"
                      color="#5E5E5E"
                      padding={2}
                      sx={{
                        bgcolor: '#F4F4F4',
                        borderRadius: 2,
                      }}
                    >
                      {t(
                        'createNewWorkspace.selectPartnerText',
                        'If you are working with specific partners, please select from the list.',
                      )}
                    </Typography>
                  </>
                )}
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                p: 2,
                gap: 1.5,
                padding: 0,
                width: 1,
              }}
            >
              <StyledButton onClick={onClose} color="secondary" fullWidth={isMobile} sx={{ minWidth: '106px' }}>
                {t('cancel', 'Cancel')}
              </StyledButton>
              <StyledButton onClick={handleSubmit(submit)} disabled={isLoading} color="success" fullWidth>
                {t('createWorkspace', 'Create workspace')}
              </StyledButton>
            </DialogActions>
          </Box>
        </Box>
        <Box display={isMobile ? 'none' : 'flex'} flexDirection="column" width={1} height={1}>
          <img
            src={createWorkspaceBackgroundImage}
            alt="Create workspace"
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '32px',
              objectFit: 'cover',
              objectPosition: '85% 50%',
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};
