import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DeviceStatus } from 'models/device.enums';
import { Site } from 'models/site';

import { DeleteModal } from '@components/modals/DeleteModal';
import { ErrorModal } from '@components/modals/ErrorModal';
import { selectDevicesBySiteUuid } from '@services/devices/selectors';
import { useAppDispatch, useAppSelector } from '@services/hooks';
import { useDeleteSiteMutation } from '@services/sites/endpoints';
import { addToastMessage } from '@services/toastMessages';

export type DeleteSiteModalProps = {
  site: Site;
  isOpen: boolean;
  closeModal: () => void;
};

export const DeleteSiteModal = ({ site, isOpen, closeModal }: DeleteSiteModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const devices = useAppSelector((state) => selectDevicesBySiteUuid(state, site?.uuid));
  const initiallyAnyOngoingTransactions = useMemo(
    () =>
      devices.some(
        (d) =>
          d.status === DeviceStatus.ONLINE &&
          d.stateOfConnectors?.some((c) => c.transactionStatus && c.transactionStatus !== 'none'),
      ),
    [site?.uuid, isOpen],
  );

  const [deleteSite, { isLoading }] = useDeleteSiteMutation();

  const handleNavigateToSites = () => window.location.pathname.includes('/sites/') && navigate('/sites');

  const handleDeleteSite = async () => {
    try {
      await deleteSite(site.uuid).unwrap();
      dispatch(
        addToastMessage({
          type: 'success',
          title: t('siteDeleted', 'Site deleted'),
          message: t('historicalDataIsStillAvailable', 'Historical data is still available in Reporting.'),
        }),
      );
      closeModal();
      handleNavigateToSites();
    } catch (e) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('deletingSiteFailed', 'Deleting the site failed'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
    }
  };

  if (initiallyAnyOngoingTransactions) {
    return (
      <ErrorModal title="Unable to delete site" isOpen={isOpen} onClose={closeModal}>
        <p className="text-vermillion">It is not possible to delete a site while it has ongoing charging sessions.</p>
        <br />
        <p>If possible, please end all ongoing sessions and try again.</p>
      </ErrorModal>
    );
  }

  return (
    <DeleteModal
      isOpen={isOpen}
      onClose={closeModal}
      onDelete={handleDeleteSite}
      isLoading={isLoading}
      confirmText={`${t('yes', 'Yes')}, ${t('deleteTheSite', 'Delete the site').toLowerCase()}`}
      cancelText={t('no', 'No')}
      title={t('deleteSite', 'Delete site')}
    >
      <div className="space-y-6 font-poppins">
        <p>
          {t('deleteSiteModalText', 'Are you sure you want to delete the site')}
          {site?.name && <span className="font-semibold"> {site.name}</span>}?
        </p>
        <p>{t('deleteSiteModalSubText', 'Devices under this site will need to be set up again under a new site.')}</p>
      </div>
    </DeleteModal>
  );
};
