import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { isEmpty } from 'lodash';
import { Device } from 'models/device';
import * as yup from 'yup';

import { Box, Typography } from '@mui/material';

import { Input } from '@components/atoms/Input';
import { PasswordInput } from '@components/atoms/PasswordInput';
import { EditingSidebarBase } from '@components/sidebars/EditingSidebarBase';
import { DEVICE_CONFIGURATION_PARAMETERS } from '@handlers/device/deviceConst';
import { useForm } from '@hooks/index';

import { useSaveDevice } from './saveDeviceHooks';

export const LmcConnectivityFormSidebar = () => {
  const { t } = useTranslation();
  const { device } = useOutletContext() as { device: Device };

  const { WIFI_SSID, WIFI_PASSWORD } = DEVICE_CONFIGURATION_PARAMETERS;
  const deviceConfiguration = device.configuration ?? {};

  const [saveDevice, { isLoading, isError }] = useSaveDevice();

  const schema = yup.object().shape({
    wifiSsid: yup.string().when('password', {
      is: (password: string) => !!password.length,
      then: yup.string().required(t('required*', 'Required*')),
    }),
    password: yup.string(),
  });

  const { formState, reset, register, handleSubmitAndResolve } = useForm({
    schema,
  });

  useEffect(() => {
    if (isEmpty(device)) {
      reset();
    } else {
      reset({
        wifiSsid: deviceConfiguration[WIFI_SSID.key]?.value,
        password: deviceConfiguration[WIFI_PASSWORD.key]?.value,
      });
    }
  }, [deviceConfiguration]);

  const saveValues = async ({ wifiSsid, password }: { wifiSsid: string; password: string }) =>
    saveDevice(device, {
      deviceConfig: [
        { key: WIFI_SSID.key, value: wifiSsid },
        ...(password ? [{ key: WIFI_PASSWORD.key, value: password }] : []),
      ],
    });

  return (
    <EditingSidebarBase
      title={t('connectivity', 'Connectivity')}
      subtitle={device.name || device.serialNumber}
      saveLabel={t('update', 'Update')}
      discardLabel={t('cancel', 'Cancel')}
      containerClassName="flex flex-col"
      anyDataChanged={formState.isDirty}
      onSaveValues={handleSubmitAndResolve(saveValues)}
      updateLoading={isLoading}
      updateFailed={isError}
      isSubSidebar
    >
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography variant="p14">{t('wifiConnectionText', 'Leave fields empty to disable WiFi.')}</Typography>
        <Input
          label={t('wifiSsid', 'WiFi SSID')}
          type="text"
          error={Boolean(formState.errors?.wifiSsid)}
          helpText={formState.errors?.wifiSsid?.message?.toString()}
          {...register('wifiSsid')}
        />
        <PasswordInput
          autoComplete="new-password"
          label={t('password', 'Password')}
          type="text"
          error={Boolean(formState.errors?.password)}
          helpText={formState.errors?.password?.message?.toString()}
          {...register('password')}
        />
      </Box>
    </EditingSidebarBase>
  );
};
