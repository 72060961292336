import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import jsCookie from 'js-cookie';

import { Spinner } from '@components/atoms/Spinner';
import { OverflowSpinner } from '@components/spinner/OverflowSpinner';
import { useSwitchToCompany } from '@hooks/useSwitchToCompany';
import { ReactComponent as ArrowLeftIcon } from '@images/icons/arrow_left.svg';
import { isSubdomainPartner } from '@routesConfig/routesUtil';
import { selectUser } from '@services/auth/selectors';
import { useGetCompaniesQuery } from '@services/companies/endpoints';
import { selectCompanies, selectPartnerCompanies } from '@services/companies/selectors';
import { useAppSelector } from '@services/hooks';
import { CreateWorkspaceModal } from '@views/Workspace/CreateWorkspaceModal';

import { LoggedOutBasicLayout } from '../layouts/LoggedOutBasicLayout';

type PartnerWorkspaceProviderContextType = {
  activePartnerCompanyUuid?: string;
  setActivePartnerCompanyUuid: (uuid: string) => void;
};

export const PartnerWorkspaceContext = createContext<PartnerWorkspaceProviderContextType>({
  activePartnerCompanyUuid: undefined,
  setActivePartnerCompanyUuid: () => {},
});

const PartnerWorkspaceProvider = ({ children }: { children: ReactNode }) => {
  const [activePartnerCompanyUuid, setActivePartnerCompanyUuid] = useState(jsCookie.get('activePartnerCompanyUuid'));

  const handleSetActivePartnerCompanyUuid = (uuid: string) => {
    jsCookie.set('activePartnerCompanyUuid', uuid);
    setActivePartnerCompanyUuid(uuid);
  };

  const context: PartnerWorkspaceProviderContextType = {
    activePartnerCompanyUuid,
    setActivePartnerCompanyUuid: handleSetActivePartnerCompanyUuid,
  };
  return <PartnerWorkspaceContext.Provider value={context}>{children}</PartnerWorkspaceContext.Provider>;
};

const WorkspaceGuardComponent = ({ children }: { children: ReactNode }) => {
  const { activePartnerCompanyUuid } = useContext(PartnerWorkspaceContext);
  const [isLoadingCompany, setIsLoadingCompany] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const allCompanies = useAppSelector(selectCompanies);
  const partnerCompanies = useAppSelector(selectPartnerCompanies);
  const switchToCompany = useSwitchToCompany();

  const [isCreateWorkspaceModalOpen, setIsCreateWorkspaceModalOpen] = useState(false);

  const { isLoading } = useGetCompaniesQuery();

  const companies = isSubdomainPartner() ? partnerCompanies : allCompanies;
  const hasAccessToActiveCompany = companies.some((company) =>
    isSubdomainPartner() ? company.uuid === activePartnerCompanyUuid : company.uuid === user?.activeCompanyUuid,
  );

  useEffect(() => {
    if (isSubdomainPartner() && !partnerCompanies.length && !isLoading) {
      navigate('/logout');
    }
  }, [partnerCompanies, isLoading]);

  if (isLoading) {
    return <OverflowSpinner size={20} fit />;
  }

  if (!hasAccessToActiveCompany) {
    return (
      <LoggedOutBasicLayout isLogin>
        {isLoadingCompany ? (
          <Spinner />
        ) : (
          <div className="w-11/12 max-w-md space-y-2 rounded-lg bg-white p-9 text-left font-poppins font-normal text-black md:w-full">
            <div className="font-everett text-3xl">{t('selectWorkspace', 'Select a workspace')}</div>
            {!isSubdomainPartner() && (
              <>
                <div className="pb-4 text-base">
                  {t('noLongerInActiveWorkspace', 'You are no longer a member of your previously active workspace.')}
                </div>
                <div className="pb-4 text-base">
                  {companies.length
                    ? `${t('selectDifferentWorkspace', 'Please select a different workspace to use')}
                    ${t('or', 'or').toLowerCase()}`
                    : t(
                        'notPartOfAnyWorkspace',
                        'As you aren’t currently a member of any other workspace, please',
                      )}{' '}
                  <span
                    className="cursor-pointer text-vermillion underline"
                    onClick={() => setIsCreateWorkspaceModalOpen(true)}
                    aria-hidden="true"
                  >
                    {t('createNewOne', 'create a new one').toLowerCase()}
                  </span>
                  .
                </div>
              </>
            )}
            <div
              className={`w-full overflow-hidden rounded-2xl bg-gray-100 ${
                companies.length ? 'shadow-[0px_-1px_0px_rgba(40,40,40,0.10)_inset]' : ''
              }`}
            >
              <div className="flex w-full justify-between p-4">
                <div className="overflow-hidden text-ellipsis">
                  <div className="overflow-hidden text-ellipsis text-tiny text-gray-600">
                    {companies.length
                      ? t('workspacesCountFor', '{{count}} workspaces for', { count: companies.length }).toLowerCase()
                      : t('signedInAs', 'Signed in as')}
                  </div>
                  <div className="overflow-hidden text-ellipsis font-semibold">{user?.email}</div>
                </div>
                <div className="ml-4 flex items-center">
                  <div
                    className="cursor-pointer whitespace-nowrap text-tiny text-forest"
                    onClick={() => navigate('/logout')}
                    aria-hidden="true"
                  >
                    {t('signOut', 'Sign out')}
                  </div>
                </div>
              </div>
              {!!companies.length && (
                <div className="max-h-64 overflow-auto border-t border-gray-200 py-2">
                  {companies.map((company) => (
                    <div
                      key={company.uuid}
                      className="flex cursor-pointer items-center justify-between px-4 py-3 hover:bg-gray-200"
                      onClick={() => {
                        setIsLoadingCompany(true);
                        switchToCompany(company);
                      }}
                      aria-hidden="true"
                    >
                      <div className="overflow-hidden text-ellipsis whitespace-nowrap">{company.name}</div>
                      <div className="flex w-8 justify-end">
                        <ArrowLeftIcon className="rotate-180" />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        <CreateWorkspaceModal
          isOpen={isCreateWorkspaceModalOpen}
          onClose={() => setIsCreateWorkspaceModalOpen(false)}
        />
      </LoggedOutBasicLayout>
    );
  }

  return children;
};

export const WorkspaceGuard = ({ children }: { children: ReactNode }) => (
  <PartnerWorkspaceProvider>
    <WorkspaceGuardComponent>{children}</WorkspaceGuardComponent>
  </PartnerWorkspaceProvider>
);
