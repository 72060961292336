import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { Autocomplete, AutocompleteValue, Chip, SxProps, Typography, createFilterOptions } from '@mui/material';

import { StyledTextField } from './StyledTextField';

export type OptionBase =
  | string
  | {
      label: string;
      value: string | null;
    };

type AutocompleteProps<OptionType, Multiple, FreeSolo, DisableClearable> = {
  label?: string;
  options?: OptionType[];
  value?: AutocompleteValue<OptionType, Multiple, DisableClearable, FreeSolo> | undefined;
  error?: { message?: string } | undefined;
  enterOnSpaceOrComma?: boolean;
  disabled?: boolean;
  itemDeletionDisabled?: boolean;
  multiple?: Multiple;
  freeSolo?: FreeSolo;
  disableClearable?: DisableClearable;
  sx?: SxProps;
  onChange?: (value: AutocompleteValue<OptionType, Multiple, DisableClearable, FreeSolo>) => void;
};

type FormAutocompleteProps<T extends FieldValues, OptionType, Multiple, FreeSolo, DisableClearable> = {
  control: Control<T>;
  name: Path<T>;
} & AutocompleteProps<OptionType, Multiple, FreeSolo, DisableClearable>;

export const StyledAutocomplete = <
  OptionType extends OptionBase,
  Multiple extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
>({
  label,
  options,
  value,
  error,
  enterOnSpaceOrComma,
  disabled,
  itemDeletionDisabled,
  multiple,
  freeSolo,
  disableClearable,
  sx,
  onChange,
}: AutocompleteProps<OptionType, Multiple, FreeSolo, DisableClearable>) => {
  const isOptionString = (option: OptionBase): option is string => typeof option === 'string';

  const errorMessage = error?.message ?? (error as any)?.value?.message;
  return (
    <Autocomplete
      multiple={multiple}
      freeSolo={freeSolo}
      options={options ?? []}
      disabled={disabled}
      getOptionLabel={(option) => (isOptionString(option) ? option : option.label ?? '')}
      getOptionKey={(option) => (isOptionString(option) ? option : option.value ?? option.label)}
      renderTags={(value, getTagProps) =>
        value.map((option, index: number) => (
          <Chip
            label={isOptionString(option) ? option : option.label}
            {...getTagProps({ index })}
            {...(itemDeletionDisabled && { onDelete: undefined })}
            key={index}
          />
        ))
      }
      renderInput={(params) => (
        <StyledTextField
          {...params}
          label={<Typography variant="p14">{label}</Typography>}
          error={Boolean(error)}
          helperText={errorMessage}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
            style: { padding: '5px 8px' },
          }}
          sx={{
            '& .MuiInputLabel-root': {
              transform: 'translate(14px, 12px)',
            },
            '& .MuiOutlinedInput-root': {
              height: 'auto',
            },
            ...sx,
          }}
        />
      )}
      renderOption={(props, option) => (
        <Typography variant="p14" {...props} key={crypto.randomUUID()} component="li" gap={2}>
          {isOptionString(option) ? option : option.label}
        </Typography>
      )}
      isOptionEqualToValue={(option, value) =>
        !isOptionString(option) && !isOptionString(value) ? option.value === value.value : option === value
      }
      value={value}
      disableClearable={disableClearable}
      forcePopupIcon={false}
      filterOptions={createFilterOptions({
        stringify: (option) => (isOptionString(option) ? option : `${option.label || ''} ${option.value}`),
      })}
      autoSelect
      filterSelectedOptions
      onKeyDown={(event) => {
        if (enterOnSpaceOrComma && (event.key === ' ' || event.key === ',')) {
          event.preventDefault();
          const enterEvent = new KeyboardEvent('keydown', { key: 'Enter', bubbles: true });
          const target = event.target as HTMLInputElement;
          target.dispatchEvent(enterEvent);
        }
      }}
      onChange={(event, value, reason) => {
        if (reason === 'blur' && !freeSolo) return;
        onChange?.(value);
      }}
    />
  );
};

export const FormAutocomplete = <
  T extends FieldValues,
  OptionType extends OptionBase,
  Multiple extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
>({
  control,
  name,
  label,
  options,
  ...props
}: FormAutocompleteProps<T, OptionType, Multiple, FreeSolo, DisableClearable>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledAutocomplete
          label={label}
          options={options}
          value={field.value}
          error={error}
          onChange={field.onChange}
          {...props}
        />
      )}
    />
  );
};
