import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { Permission } from 'models/permission.enum';

import NoItemsCard from '@components/cards/NoItemsCard';
import { selectPermissionByName } from '@services/auth/selectors';
import { selectActiveCompanyUuid } from '@services/companies/selectors';
import { useAppSelector } from '@services/hooks';
import { selectRfidCardsByCompanyUuid } from '@services/rfidCards/selectors';

import { RfidCardsMaterialTable } from './RfidCardsTable/RfidCardsMaterialTable';

export const WorkspaceRfidCardsView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { companyUuid: companyUuidFromUrl, rfidCardUuid } = useParams();
  const activeCompanyUuid = useAppSelector(selectActiveCompanyUuid);
  const companyUuid = companyUuidFromUrl ?? activeCompanyUuid;

  const rfidCards = useAppSelector((state) => selectRfidCardsByCompanyUuid(state, companyUuid));

  const canAddRfidCards = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_ADD_RFID_CARDS));

  const selectedRfidCard = rfidCards.find((rfidCard) => rfidCard.uuid === rfidCardUuid);
  return (
    <>
      {!rfidCards.length ? (
        <NoItemsCard
          message={t('noRfidCardsText', 'There are currently no RFID cards in your workspace.')}
          {...(canAddRfidCards && {
            buttonLabel: t('addNewItem', 'Add a new {{item}}', { item: t('rfidCard', 'RFID card') }),
            onButtonClick: () => navigate('./add'),
          })}
        />
      ) : (
        <RfidCardsMaterialTable />
      )}
      <Outlet context={{ rfidCard: selectedRfidCard }} />
    </>
  );
};
