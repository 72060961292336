import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { every } from 'lodash';
import { DeviceStatus, DeviceType } from 'models/device.enums';
import PropTypes from 'prop-types';

import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/outline';

import Autocomplete from '@components/atoms/Autocomplete';
import { Checkbox } from '@components/atoms/Checkbox';
import { Input } from '@components/atoms/Input';
import Select from '@components/atoms/Select';
import { ErrorModal } from '@components/modals/ErrorModal';
import { Modal } from '@components/modals/Modal';
import { useForm } from '@hooks';
import { useUpdateDeviceMutation } from '@services/devices/endpoints';
import { useAddSiteMutation, useGetSitesQuery } from '@services/sites/endpoints';
import { selectActiveCompanySites } from '@services/sites/selectors';

import useSchema from './deviceEditModalValidationSchema';
import './index.scss';

const DeviceEditModal = ({ open, closeModal, pullDeviceAgain, device, site }) => {
  const { t } = useTranslation();
  const sites = useSelector(selectActiveCompanySites);
  const deviceTypes = Object.entries(DeviceType).map(([value, label]) => ({
    value,
    label,
  }));
  const [showAddNewSite, setShowAddNewSite] = useState(false);

  useGetSitesQuery({});

  const [updateDevice, { isLoading }] = useUpdateDeviceMutation();
  const [addSite, { isLoading: isSiteSaveLoading }] = useAddSiteMutation();

  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [gridConnectionOcppError, setGridConnectionOcppError] = useState(null);
  const [originalGridConnection, setOriginalGridConnection] = useState(null);

  const activeDeviceType = deviceTypes.find((deviceType) => device.type === deviceType.label);
  const isVoolLmc = !!device.voolDeviceFlag && activeDeviceType.label === DeviceType.LMC;
  const gridConnectionEditable = !isVoolLmc || device.status === DeviceStatus.ONLINE;
  const gridConnectionRequired = isVoolLmc && device.status === DeviceStatus.ONLINE;
  const schema = useSchema({ gridConnectionRequired });
  const { register, handleSubmit, formState, formError, setFormError, setValue, resetField, watch } = useForm({
    schema,
  });
  const selectedSiteUuid = watch('siteUuid');

  //
  // Save site (if new set) and save new device
  //
  const onSubmit = async ({ deviceUuid, name, siteUuid, siteName, siteAddress, sitePublicFlag, gridConnection }) => {
    try {
      let newSite;
      if (every([siteName, siteAddress])) {
        newSite = await addSite({
          name: siteName,
          address: siteAddress,
          publicFlag: sitePublicFlag ? 1 : 0,
        });

        if (newSite?.error) {
          throw new Error(
            `${t('addingNewSiteError', 'Adding new site: {{error}}', { error: newSite?.error?.data?.message })}`,
          );
        }

        resetField('siteName');
        resetField('siteAddress');
        resetField('sitePublicFlag');
        setValue('newSite', false);
        setValue('siteUuid', newSite?.data?.uuid);
      }
      setOriginalGridConnection(device.gridConnection);
      const updateDeviceResponse = await updateDevice({
        uuid: deviceUuid,
        name: name?.trim() || null,
        siteUuid: newSite?.data?.uuid || siteUuid,
        ...(!!Number(gridConnection) && { gridConnection }),
      });

      if (updateDeviceResponse?.error) {
        setShowAddNewSite(false);
        throw new Error(updateDeviceResponse?.error?.data?.message);
      }

      if (updateDeviceResponse?.data?.gridConnectionOcppError) {
        setGridConnectionOcppError(updateDeviceResponse.data.gridConnectionOcppError);
        setErrorModalVisible(true);
      } else {
        pullDeviceAgain();
        closeModal();
      }
    } catch (error) {
      setFormError(error?.message || t('somethingWentWrong', 'Something went wrong'));
    }
  };
  //
  // <-- Save site (if new set) and save new device -->
  //

  useEffect(() => {
    setValue('deviceUuid', device?.uuid);
    setValue('siteUuid', site?.uuid);
    setValue('deviceType', device?.type);
    setValue('gridConnection', device?.gridConnection || '');
    if (device.name) {
      setValue('name', device.name);
    }
  }, [site?.uuid, device?.uuid]);

  if (errorModalVisible) {
    let errorDescription;
    if (gridConnectionOcppError === 'Rejected') {
      errorDescription = t('gridConnectionOcppRejectedError', 'The LMC rejected the Grid connection change.');
    } else if (['UnknownComponent', 'UnknownVariable'].includes(gridConnectionOcppError)) {
      errorDescription = t(
        'gridConnectionOcppUnknownError',
        'The LMC does not support changing Grid connection via web.',
      );
    } else {
      errorDescription = t('someUnknownErrorOcurred', 'Some unknown error occurred.');
    }
    const gridConnectionChangedInDb = device.gridConnection !== originalGridConnection;
    return (
      <ErrorModal
        isOpen
        closeModal={() => {
          setErrorModalVisible(false);
          closeModal();
        }}
        title={t('failedToSetGridConnectionOnLmc', 'Failed to set Grid connection on LMC')}
      >
        <p className="mb-2 text-vermillion">{errorDescription}</p>
        <p>
          {gridConnectionChangedInDb ? (
            <>
              {t('gridConnectionHasChangedText', 'Grid connection value has been changed in the webapp.')}
              <br />
              {t('gridConnectionHasChangedSubText', 'Note that the value on LMC might be different.')}
            </>
          ) : (
            `${t('gridConnectionUnchangedText', 'Grid connection value is left unchanged.')}`
          )}
        </p>
      </ErrorModal>
    );
  }

  return (
    <Modal
      isOpen={open}
      onClose={closeModal}
      onConfirm={() => {
        handleSubmit(onSubmit)();
      }}
      isLoading={isLoading || isSiteSaveLoading}
      confirmText={t('save', 'Save')}
      title={`${t('edit', 'Edit')} ${t('device', 'Device')}`}
      content={() => t('deviceEditModalTitle', 'Here you can modify your device configuration.')}
      {...(formError && {
        error: formError,
      })}
    >
      <div className="my-5 border-t border-gray-100 dark:border-truegray-700" />
      <form className="mb-2 space-y-4">
        <Input
          label={t('serialNumber', 'Serial number')}
          name="serialNr"
          error={false}
          inputProps={{
            value: device.serialNumber,
            disabled: true,
          }}
        />
        <Input
          label={`${t('pinCode', 'Pin code')}`}
          name="pin"
          className="text-gray-500 dark:text-warmgray-400"
          inputProps={{
            value: device.pinCode || '',
            disabled: true,
          }}
          error={false}
        />
        <Input label="Device name (optional)" name="name" error={false} {...register('name')} />
        <Select
          label={t('typeOfDevice', 'Type of device')}
          name="deviceType"
          disabled
          options={deviceTypes}
          value={activeDeviceType.value}
        />
        {(activeDeviceType.label === DeviceType.LMC || activeDeviceType.label === DeviceType.CHARGER) && (
          <Input
            type="number"
            label={t('gridConnectionInputLabel', 'Grid connection (in A)')}
            name="gridConnection"
            questionMark={t('valueMustBeNumber', 'This value must be a number.')}
            error={Boolean(formState.errors?.gridConnection)}
            helpText={
              isVoolLmc && device.status !== DeviceStatus.ONLINE
                ? t('gridConnectionEditCondition', 'Can be edited when the LMC is online')
                : formState.errors?.gridConnection?.message
            }
            {...register('gridConnection', {
              required: gridConnectionRequired,
            })}
            inputProps={{
              ...(activeDeviceType.label === DeviceType.CHARGER && {
                placeholder: t('byDefault16A', 'By default {{gridConnection}}A', { gridConnection: 16 }),
              }),
              disabled: !gridConnectionEditable,
            }}
          />
        )}
        {!showAddNewSite && (
          <Autocomplete
            label={t('site', 'Site')}
            name="siteUuid"
            required
            options={sites.map((s) => ({ label: s.name, value: s.uuid }))}
            {...(selectedSiteUuid && {
              value: selectedSiteUuid,
            })}
            onChange={(val) => setValue('siteUuid', val)}
          />
        )}
      </form>
      {showAddNewSite ? (
        <>
          <div className="mt-8 border-t border-primary pt-4">
            <div className="flex items-center">
              <div className="text-md flex-grow">{t('newSite', 'New Site')}</div>
              <button
                type="button"
                className="flex text-xs text-gray-500 dark:text-warmgray-400"
                onClick={() => {
                  setShowAddNewSite(false);
                  setValue('newSite', false);
                }}
              >
                {t('cancel', 'Cancel')}
                <XCircleIcon className="ml-1 h-4 w-4" />
              </button>
            </div>
          </div>
          <div className="mb-2 mt-4 space-y-4">
            <div>
              <Input
                label={`${t('name', 'Name')}*`}
                name="siteName"
                error={Boolean(formState.errors?.siteName)}
                helpText={formState.errors?.siteName?.message}
                {...register('siteName')}
              />
            </div>
            <div>
              <Input
                label={`${t('address', 'Address')}*`}
                name="siteAddress"
                error={Boolean(formState.errors?.siteAddress)}
                helpText={formState.errors?.siteAddress?.message}
                {...register('siteAddress')}
              />
            </div>
            <div>
              <Checkbox label={t('publicSite', 'Public site')} name="sitePublicFlag" {...register('sitePublicFlag')} />
            </div>
          </div>
        </>
      ) : (
        <div className="mt-3 flex justify-end">
          <button
            type="button"
            className="flex text-xs text-gray-500 dark:text-warmgray-400"
            onClick={() => {
              setShowAddNewSite(true);
              setValue('newSite', true);
            }}
          >
            {t('addNewSite', 'Add a new site')}
            <PlusCircleIcon className="ml-1 h-4 w-4" />
          </button>
        </div>
      )}
    </Modal>
  );
};

DeviceEditModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  pullDeviceAgain: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired,
  site: PropTypes.object,
};

DeviceEditModal.defaultProps = {
  open: false,
  site: null,
};

export default DeviceEditModal;
