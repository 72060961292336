import { useTranslation } from 'react-i18next';

import { FeatureDto, FeatureId } from 'models/subscriptions';
import moment from 'moment';

import { Box, BoxProps, Typography, useTheme } from '@mui/material';

type SubscriptionPriceProps = BoxProps & {
  feature: FeatureDto;
  hasDiscount: boolean;
};

export const SubscriptionPrice = ({ feature, hasDiscount, ...props }: SubscriptionPriceProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const featureItemMap: Record<FeatureId, string> = {
    'device.public-charging': t('connector', 'Connector'),
    'company.report-schedules': t('report', 'Report'),
    'device.third-party-chargers': t('charger', 'Charger'),
  };

  return (
    <Box display="flex" flexDirection="column" {...props}>
      <Box display="flex" gap={0.5}>
        <Typography
          variant="h3"
          color={theme.palette.raisingBlack}
          sx={{ textDecoration: hasDiscount ? 'line-through' : 'none' }}
        >
          {Number(feature.featurePrice.price).toFixed(2)}€
        </Typography>
        {feature.featureDiscount?.companyDiscount && (
          <Typography variant="h3" color={theme.palette.mediumVermilion}>
            {Number(feature.featurePrice.price * (1 - feature.featureDiscount.discountPercentage)).toFixed(2)}€
          </Typography>
        )}
      </Box>
      <Typography variant="p14" color={theme.palette.darkGray}>
        {t('perItemAMonth', 'per {{item}} a month', { item: featureItemMap[feature.featureId].toLowerCase() })}
        {feature.featureId === FeatureId.DEVICE_PUBLIC_CHARGING &&
          t('plusPercentageOfRevenue', ' + {{percentage}}% of revenue', {
            percentage: feature.featurePrice.revenueSharePercentage
              ? (Number(feature.featurePrice.revenueSharePercentage) * 100).toFixed(0)
              : 0,
          })}
      </Typography>
      {hasDiscount && (
        <Typography variant="p14" color={theme.palette.darkGray}>
          {t('validUntilDate', 'valid until {{date}}', {
            date: feature.featureDiscount?.companyDiscount?.validTo
              ? moment(feature.featureDiscount?.companyDiscount?.validTo).format('DD.MM.YYYY')
              : 'N/A',
          })}
        </Typography>
      )}
    </Box>
  );
};
