import React, { Dispatch, SetStateAction, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FEATURE_WIP } from 'featureFlags';

import { Box, Divider, Drawer, IconButton, SwipeableDrawer, Typography, useMediaQuery, useTheme } from '@mui/material';

import closeIcon from '@images/icons/Close.svg';
import hamburgerMenuIcon from '@images/icons/hamburgerMenu.svg';
import { ReactComponent as SidebarToggleIcon } from '@images/icons/nav/Sidebar_toggle.svg';
import { ReactComponent as VoolAsterisk } from '@images/icons/nav/Vool_asterisk.svg';
import { ReactComponent as VoolLogo } from '@images/icons/nav/Vool_logo.svg';
import { NavigationItem, useNavigationItems } from '@routesConfig/useNavigationItems';

import LanguageSelector from './LanguageSelector';
import { NavigationItemComponent } from './NavigationItemComponent';

type NavigationComponentProps = {
  isCollapsed: boolean;
  setIsCollapsed: Dispatch<SetStateAction<boolean | undefined>>;
};

export const NavigationComponent = ({ isCollapsed, setIsCollapsed }: NavigationComponentProps) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigationRoutes = useNavigationItems();

  const [isHovered, setIsHovered] = useState(false);
  const [isMobileSubMenuOpen, setIsMobileSubMenuOpen] = useState(false);

  const shouldExpandNavigation = (isHovered && !isDesktop) || (!isCollapsed && isDesktop);

  const toggleIsCollapsed = () => setIsCollapsed((prev) => !prev);
  const toggleIsMobileSubMenuOpen = () => setIsMobileSubMenuOpen((prev) => !prev);

  return (
    <Drawer
      anchor={isMobile ? 'bottom' : 'left'}
      open={!isCollapsed}
      variant="permanent"
      PaperProps={{
        sx: {
          zIndex: 40,
          borderRight: 'none',
        },
      }}
      onClose={() => setIsCollapsed(true)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {!isMobile && (
        <>
          <button type="button" onClick={toggleIsCollapsed}>
            <Box
              display="flex"
              justifyContent={shouldExpandNavigation ? 'space-between' : 'center'}
              minWidth={shouldExpandNavigation ? 288 : 80}
              p={3}
            >
              {shouldExpandNavigation ? (
                <>
                  <VoolLogo />
                  {isDesktop && <SidebarToggleIcon />}
                </>
              ) : (
                <VoolAsterisk />
              )}
            </Box>
          </button>
          <Divider />
        </>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        sx={
          isMobile
            ? {
                flexDirection: 'row',
                pt: 0,
                px: 2,
              }
            : {
                flexDirection: 'column',
                gap: 2,
                pt: 3,
              }
        }
      >
        {navigationRoutes.map((item) => {
          const getIsRouteActive = (route: NavigationItem) => !!route.path && pathname.includes(route.path);
          if (item.children) {
            return isMobile ? (
              <React.Fragment key={item.key}>
                <IconButton onClick={toggleIsMobileSubMenuOpen}>
                  <img src={isMobileSubMenuOpen ? closeIcon : hamburgerMenuIcon} alt="subMobileMenu" />
                </IconButton>
                <SwipeableDrawer
                  anchor="bottom"
                  open={isMobileSubMenuOpen}
                  PaperProps={{
                    sx: {
                      borderTopLeftRadius: 20,
                      borderTopRightRadius: 20,
                      marginBottom: 7,
                    },
                  }}
                  ModalProps={{
                    sx: {
                      zIndex: 30,
                    },
                  }}
                  onOpen={() => setIsMobileSubMenuOpen(true)}
                  onClose={() => setIsMobileSubMenuOpen(false)}
                >
                  <Box display="flex" flexDirection="column" gap={2} px={2} py={3}>
                    {item.children.map((route) => (
                      <NavigationItemComponent
                        key={route.key}
                        route={route}
                        isActive={getIsRouteActive(route)}
                        onCloseDrawer={toggleIsMobileSubMenuOpen}
                      />
                    ))}
                  </Box>
                </SwipeableDrawer>
              </React.Fragment>
            ) : (
              <React.Fragment key={item.key}>
                <Box display="flex" flexDirection="column" gap={2} px={2}>
                  {shouldExpandNavigation && (
                    <Typography variant="p14b" px={1.5}>
                      {item.name}
                    </Typography>
                  )}
                  {item.children.map((route) => (
                    <NavigationItemComponent
                      key={route.key}
                      route={route}
                      isActive={getIsRouteActive(route)}
                      hideRouteName={!shouldExpandNavigation || isMobile}
                    />
                  ))}
                </Box>
                <Divider />
              </React.Fragment>
            );
          }
          return (
            <Box
              key={item.key}
              sx={
                isMobile
                  ? {
                      px: 0,
                      py: 0.5,
                    }
                  : {
                      px: 2,
                    }
              }
            >
              <NavigationItemComponent
                route={item}
                isActive={getIsRouteActive(item)}
                hideRouteName={!shouldExpandNavigation || isMobile}
                {...(isMobile && { bgColor: '#EB4E20', borderRadius: 37.5 })}
                onCloseDrawer={isMobileSubMenuOpen ? toggleIsMobileSubMenuOpen : undefined}
              />
            </Box>
          );
        })}
      </Box>
      {FEATURE_WIP && shouldExpandNavigation && !isMobile && (
        <Box p={2}>
          <LanguageSelector />
        </Box>
      )}
    </Drawer>
  );
};
