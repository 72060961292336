import { useTranslation } from 'react-i18next';

import { Device } from 'models/device';
import { DeviceStatus } from 'models/device.enums';
import * as yup from 'yup';

export type LmcSettingsForm = {
  deviceName: string | null;
  gridConnection: number;
  powerSharingMethod: string;
  powerMeterType: { label: string; value: string } | null;
  powerMeterIpAddress: string;
  solarInverterType: { label: string; value: string } | null;
  solarInverterIpAddress: string;
  ocppHostAddress: string;
  ocppServerReconnectTime: number;
  mainsMeteringMode: string;
  externalAntennaEnabled: boolean;
};

export const lmcSettingsFormDefaultValues: LmcSettingsForm = {
  deviceName: null,
  gridConnection: 0,
  powerSharingMethod: '',
  powerMeterType: null,
  powerMeterIpAddress: '',
  solarInverterType: null,
  solarInverterIpAddress: '',
  ocppHostAddress: '',
  ocppServerReconnectTime: 0,
  mainsMeteringMode: '',
  externalAntennaEnabled: false,
};

type UseLmcSettingsSchemaProps = {
  device: Device;
  isAdmin: boolean;
  isInstaller: boolean;
};

export const useLmcSettingsSchema = ({ device, isAdmin, isInstaller }: UseLmcSettingsSchemaProps) => {
  const { t } = useTranslation();
  const requiredLabel = t('required*', 'Required*');
  const isOffline = device.status === DeviceStatus.OFFLINE;
  const validateIp = (value?: string) => {
    const segments = value?.split('.');
    return (
      segments?.length === 4 &&
      segments.every((segment) => segment.length && Number(segment) >= 0 && Number(segment) <= 255)
    );
  };

  const autocompleteObject = yup
    .object()
    .shape({
      label: yup.string().required(requiredLabel),
      value: yup.string().required(requiredLabel),
    })
    .nullable()
    .required(requiredLabel);

  return yup
    .object()
    .shape({
      deviceName: yup.string(),
      gridConnection: yup
        .number()
        .typeError(t('mustBeNumber', 'Must be a number'))
        .required(requiredLabel)
        .test('positive', t('mustBePositive', 'Must be positive'), (value) => !!value && value > 0),
      ...(!isOffline && {
        powerSharingMethod: yup.string().required(requiredLabel),
        powerMeterType: autocompleteObject,
        powerMeterIpAddress: yup
          .string()
          .required(requiredLabel)
          .test('ipAddress', t('mustBeValidIpAddress', 'Must be a valid IP address'), validateIp),
        solarInverterType: autocompleteObject,
        solarInverterIpAddress: yup
          .string()
          .required(requiredLabel)
          .test('ipAddress', t('mustBeValidIpAddress', 'Must be a valid IP address'), validateIp),
        ...(isAdmin && {
          ocppHostAddress: yup.string().required(requiredLabel),
          ocppServerReconnectTime: yup
            .number()
            .typeError(t('mustBeNumber', 'Must be a number'))
            .required(requiredLabel)
            .test('positive', t('mustBePositive', 'Must be positive'), (value) => !!value && value > 0),
        }),
        ...((isAdmin || isInstaller) && {
          mainsMeteringMode: yup.string().required(requiredLabel),
        }),
        externalAntennaEnabled: yup.bool(),
      }),
    })
    .required();
};
