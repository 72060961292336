import { ReactNode, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { ButtonColor, StyledButton } from '@components/atoms/StyledButton';
import { OverflowSpinner } from '@components/spinner/OverflowSpinner';
import { ReactComponent as CloseIcon } from '@images/icons/Close.svg';

export type ModalProps = {
  isOpen: boolean;
  title: string;
  isPersistent?: boolean;
  confirmText?: string;
  cancelText?: string;
  noActions?: boolean;
  confirmButtonDisabled?: boolean;
  confirmButtonColor?: ButtonColor;
  isLoading?: boolean;
  error?: string;
  Content?: React.ComponentType;
  children: ReactNode;
  onClose: (event: SyntheticEvent, reason: 'backdropClick' | 'escapeKeyDown' | 'closeClick' | 'cancelClick') => void;
  onConfirm?: (event: SyntheticEvent) => void;
};

export const Modal = ({
  isOpen,
  title,
  isPersistent,
  cancelText,
  confirmText,
  noActions,
  confirmButtonDisabled,
  confirmButtonColor = 'success',
  isLoading,
  error,
  Content,
  children,
  onClose,
  onConfirm,
}: ModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={isOpen}
      onClose={isPersistent ? undefined : onClose}
      fullWidth
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          borderRadius: isMobile ? 0 : '16px',
          width: '100%',
          maxWidth: isMobile ? '100%' : '480px',
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: 'rgba(40, 40, 40, 0.5)',
          },
        },
      }}
      sx={{
        zIndex: 40,
      }}
    >
      <DialogTitle display="flex" justifyContent="space-between" alignItems="center" variant="h3">
        {title}
        {!isPersistent && (
          <IconButton aria-label="close" onClick={(e) => onClose(e, 'closeClick')}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
        {error && (
          <Typography color="error" variant="p12">
            {error}
          </Typography>
        )}
        {Content && (
          <Typography variant="p12" color="#6b7280">
            <Content />
          </Typography>
        )}
        {children}
      </DialogContent>

      {!noActions && (
        <DialogActions sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', p: 2, gap: 1.5 }}>
          <StyledButton onClick={(e) => onClose(e, 'cancelClick')} fullWidth color="secondary">
            {cancelText ?? t('cancel', 'Cancel')}
          </StyledButton>
          {onConfirm && (
            <StyledButton onClick={onConfirm} disabled={confirmButtonDisabled} color={confirmButtonColor} fullWidth>
              {confirmText ?? t('confirm', 'Confirm')}
            </StyledButton>
          )}
        </DialogActions>
      )}
      {isLoading && <OverflowSpinner className="m-1 rounded-2xl" />}
    </Dialog>
  );
};
