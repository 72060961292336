import { Navigate } from 'react-router-dom';

import BackofficeView from '@views/Backoffice';
import BackofficeDevicesView from '@views/Backoffice/Devices';
import BackofficeUsersView from '@views/Backoffice/Users';
import { ChargerActivity } from '@views/Chargers/ChargerActivity';
import { ChargerHistory } from '@views/Chargers/ChargerHistory';
import DashboardView from '@views/Dashboard';
import AddDeviceSidebar from '@views/Devices/AddDeviceSidebar';
import DeviceDetailsSidebar from '@views/Devices/DeviceDetailsSidebar';
import { DeviceConnectivityFormSidebar } from '@views/Devices/DeviceDetailsSidebar/DeviceConnectivityFormSidebar';
import { ChargerConnectivityChoiceSidebar } from '@views/Devices/DeviceDetailsSidebar/DeviceConnectivityFormSidebar/ChargerConnectivityChoiceSidebar';
import { ChargerConnectivityWizardSidebar } from '@views/Devices/DeviceDetailsSidebar/DeviceConnectivityFormSidebar/ChargerConnectivityWizardSidebar';
import { DeviceSettingsFormSidebar } from '@views/Devices/DeviceDetailsSidebar/DeviceSettingsFormSidebar/DeviceSettingsFormSidebar';
import { DeviceOverview } from '@views/Devices/DeviceOverview';
import { OcppVariablesSidebar } from '@views/Devices/OcppVariablesSidebar/OcppVariablesSidebar';
import { SingleDevice } from '@views/Devices/SingleDevice';
import { InverterHistory } from '@views/Inverters/InverterHistory';
import { InverterOverview } from '@views/Inverters/InverterOverview';
import { SingleInverterView } from '@views/Inverters/SingleInverterView';
import { Logout } from '@views/Logout';
import { LogsView } from '@views/Logs/LogsView';
import { OverviewMap } from '@views/Map/OverviewMap';
import Register from '@views/Register';
import { ResetPassword } from '@views/ResetPassword';
import { SiteListView } from '@views/Sites';
import { SingleSiteView } from '@views/Sites/SingleSiteView';
import { AddSiteGroupSidebar } from '@views/Sites/sidebars/AddSiteGroupSidebar';
import AddSiteSidebar from '@views/Sites/sidebars/AddSiteSidebar';
import SiteAvailabilitySidebar from '@views/Sites/sidebars/SiteAvailabilitySidebar';
import SiteDetailsSidebar from '@views/Sites/sidebars/SiteDetailsSidebar';
import { Vehicles } from '@views/Vehicles';
import { VehicleView } from '@views/Vehicles/VehicleView';
import VehicleHistory from '@views/Vehicles/VehicleView/VehicleHistory';
import VehicleOverview from '@views/Vehicles/VehicleView/VehicleOverview';
import { VehicleDetailsSidebar } from '@views/Vehicles/sidebars/VehicleDetailsSidebar';
import { Welcome } from '@views/Welcome';
import { ForgotPassword } from '@views/Welcome/ForgotPassword';
import { Login } from '@views/Welcome/Login';
import { WorkspaceBaseView } from '@views/Workspace/WorkspaceBaseView';
import { WorkspaceGroupsView } from '@views/Workspace/WorkspaceGroupsView';
import { WorkspaceMembersView } from '@views/Workspace/WorkspaceMembersView';
import { WorkspaceRfidCardsView } from '@views/Workspace/WorkspaceRfidCardsView';
import { WorkspaceSettingsView } from '@views/Workspace/WorkspaceSettingsView';
import { WorkspaceInvoicesView } from '@views/Workspace/invoices/WorkspaceInvoicesView';
import { WorkspaceInviteSidebar } from '@views/Workspace/sidebars/WorkspaceInviteSidebar';
import { CompanyDetails } from '@views/Workspace/sidebars/companyDetails/CompanyDetails';
import { CompanyDetailsSidebarBase } from '@views/Workspace/sidebars/companyDetails/CompanyDetailsSidebarBase';
import { EditCompanyPartnershipSidebar } from '@views/Workspace/sidebars/companyPartnership/EditCompanyPartnerShipSidebar';
import { CreateGroupSidebar } from '@views/Workspace/sidebars/groups/CreateGroupSidebar';
import { EditGroupSidebar } from '@views/Workspace/sidebars/groups/EditGroupSidebar';
import { CompanyPaymentMethod } from '@views/Workspace/sidebars/paymentMethod/CompanyPaymentMethod';
import { PaymentMethodSidebar } from '@views/Workspace/sidebars/paymentMethod/PaymentMethodSidebar';
import { PayoutDetails } from '@views/Workspace/sidebars/payoutDetails/PayoutDetails';
import { PayoutDetailsSidebar } from '@views/Workspace/sidebars/payoutDetails/PayoutDetailsSidebar';
import AddRfidCardSidebar from '@views/Workspace/sidebars/rfidCard/AddRfidCardSidebar';
import RfidCardDetailsSidebar from '@views/Workspace/sidebars/rfidCard/RfidCardDetailsSidebar';
import { WorkspaceSetupSidebar } from '@views/Workspace/sidebars/setup/WorkspaceSetupSidebar';
import { SubscriptionsSidebar } from '@views/Workspace/subscriptions/SubscriptionsSidebar';
import { WorkspaceSubscriptionsView } from '@views/Workspace/subscriptions/WorkspaceSubscriptionsView';
import { PartnersDashboard } from '@views/partners/PartnersDashboard/PartnersDashboard';
import { PartnersWorkspacesMaterialTable } from '@views/partners/PartnersWorkspacesList/PartnersWorkspacesMaterialTable';
import { PartnerWorkspaceDevicesView } from '@views/partners/partnerWorkspaceView/PartnerWorkspaceDevicesView';
import { PartnerWorkspaceSitesView } from '@views/partners/partnerWorkspaceView/PartnerWorkspaceSitesView';
import { PartnerWorkspaceView } from '@views/partners/partnerWorkspaceView/PartnerWorkspaceView';
import { ReportScheduleFilterSidebar } from '@views/reporting/ReportScheduleFilterSidebar';
import { ReportSchedulerView } from '@views/reporting/ReportSchedulerView';
import { ReportingSessionsView } from '@views/reporting/ReportingSessionsView';
import { ReportingView } from '@views/reporting/ReportingView';
import { ScheduleReportSidebar } from '@views/reporting/ScheduleReportSidebar';

import { DEFAULT_PATH } from './routesConst';

export type Route = {
  path: string;
  component: () => JSX.Element;
  children?: Route[];
};

const getDeviceDetailsSidebarRoutes = ({ withDeviceUuid } = { withDeviceUuid: false }): Route[] => [
  {
    path: `details${withDeviceUuid ? '/:deviceUuid' : ''}`,
    component: () => <DeviceDetailsSidebar />,
    children: [
      {
        path: 'settings',
        component: () => <DeviceSettingsFormSidebar />,
      },
      {
        path: 'connectivity',
        component: () => <DeviceConnectivityFormSidebar />,
        children: [
          {
            path: 'choice',
            component: () => <ChargerConnectivityChoiceSidebar />,
          },
          {
            path: 'wizard',
            component: () => <ChargerConnectivityWizardSidebar />,
          },
        ],
      },
    ],
  },
  {
    path: `ocpp-variables${withDeviceUuid ? '/:deviceUuid' : ''}`,
    component: () => <OcppVariablesSidebar />,
  },
];

const getSitesRoutes = ({ isPartnerCompany }: { isPartnerCompany?: boolean } = {}): Route => ({
  path: 'sites',
  component: () => (isPartnerCompany ? <PartnerWorkspaceSitesView /> : <SiteListView />),
  children: [
    {
      path: 'menu-details',
      component: () => <SiteDetailsSidebar />,
    },
    ...(!isPartnerCompany
      ? [
          {
            path: 'new',
            component: () => <AddSiteSidebar />,
          },
          {
            path: 'menu-availability',
            component: () => <SiteAvailabilitySidebar />,
          },
        ]
      : []),
  ],
});

const getSiteRoutes = ({ isPartnerCompany }: { isPartnerCompany?: boolean } = {}): Route => ({
  path: 'sites/:siteUuid',
  component: () => <SingleSiteView />,
  children: [
    {
      path: 'details',
      component: () => <SiteDetailsSidebar />,
    },
    {
      path: 'addDevice',
      component: () => <AddDeviceSidebar />,
    },
    {
      path: 'addGroup',
      component: () => <AddSiteGroupSidebar />,
    },
    ...getDeviceDetailsSidebarRoutes({ withDeviceUuid: true }),
    ...(!isPartnerCompany
      ? [
          {
            path: 'availability',
            component: () => <SiteAvailabilitySidebar />,
          },
          {
            path: 'subscribe',
            component: () => <SubscriptionsSidebar />,
            children: [
              {
                path: 'complete',
                component: () => <></>,
              },
            ],
          },
          {
            path: 'setup',
            component: () => <WorkspaceSetupSidebar />,
            children: [
              {
                path: 'paymentMethod',
                component: () => <CompanyPaymentMethod isSetup />,
              },
              {
                path: 'companyDetails',
                component: () => <CompanyDetails isSetup />,
              },
              {
                path: 'payoutDetails',
                component: () => <PayoutDetails isSetup />,
              },
            ],
          },
        ]
      : []),
  ],
});

const getDeviceRoutes = (): Route => ({
  path: 'devices/:deviceUuid',
  component: () => <SingleDevice />,
  children: [
    {
      path: '',
      component: () => <Navigate replace to="overview" />,
    },
    {
      path: 'overview',
      component: () => <DeviceOverview />,
      children: getDeviceDetailsSidebarRoutes(),
    },
    {
      path: 'activity',
      component: () => <ChargerActivity />,
      children: getDeviceDetailsSidebarRoutes(),
    },
    {
      path: 'sessions',
      component: () => <ChargerHistory />,
      children: getDeviceDetailsSidebarRoutes(),
    },
  ],
});

const getInverterRoutes = (): Route => ({
  path: 'inverters/:inverterUuid',
  component: () => <SingleInverterView />,
  children: [
    {
      path: '',
      component: () => <Navigate replace to="overview" />,
    },
    {
      path: 'overview',
      component: () => <InverterOverview />,
    },
    {
      path: 'sessions',
      component: () => <InverterHistory />,
    },
  ],
});

const getReportingRoutes = ({ isPartnerCompany }: { isPartnerCompany?: boolean } = {}): Route => ({
  path: 'reporting',
  component: () => <ReportingView />,
  children: [
    {
      path: '',
      component: () => <Navigate replace to="sessions" />,
    },
    {
      path: 'sessions',
      component: () => <ReportingSessionsView />,
    },
    ...(!isPartnerCompany
      ? [
          {
            path: 'scheduler',
            component: () => <ReportSchedulerView />,
            children: [
              {
                path: 'schedule',
                component: () => <ScheduleReportSidebar />,
                children: [
                  {
                    path: 'filter/:filter',
                    component: () => <ReportScheduleFilterSidebar />,
                  },
                ],
              },
              {
                path: 'schedule/:scheduleUuid',
                component: () => <ScheduleReportSidebar />,
                children: [
                  {
                    path: 'filter/:filter',
                    component: () => <ReportScheduleFilterSidebar />,
                  },
                ],
              },
            ],
          },
        ]
      : []),
  ],
});

export const partnerRoutes: Route[] = [
  {
    path: '/dashboard',
    component: () => <PartnersDashboard />,
  },
  {
    path: '/workspaces',
    component: () => <PartnersWorkspacesMaterialTable />,
  },
  {
    path: '/workspaces/:companyUuid',
    component: () => <PartnerWorkspaceView />,
    children: [
      {
        path: '',
        component: () => <Navigate replace to="sites" />,
      },
      getSiteRoutes({ isPartnerCompany: true }),
      getSitesRoutes({ isPartnerCompany: true }),
      {
        path: 'devices',
        component: () => <PartnerWorkspaceDevicesView />,
        children: [
          ...getDeviceDetailsSidebarRoutes({ withDeviceUuid: true }),
          {
            path: 'addGroup',
            component: () => <AddSiteGroupSidebar />,
          },
        ],
      },
      getDeviceRoutes(),
      getInverterRoutes(),
      {
        path: 'members',
        component: () => <WorkspaceMembersView />,
        children: [
          {
            path: 'invite',
            component: () => <WorkspaceInviteSidebar />,
          },
        ],
      },
      {
        path: 'groups',
        component: () => <WorkspaceGroupsView />,
        children: [
          {
            path: 'create',
            component: () => <CreateGroupSidebar />,
          },
          {
            path: ':groupUuid',
            component: () => <EditGroupSidebar />,
          },
        ],
      },
      {
        path: `rfidCards`,
        component: () => <WorkspaceRfidCardsView />,
        children: [
          {
            path: 'add',
            component: () => <AddRfidCardSidebar />,
          },
          {
            path: ':rfidCardUuid',
            component: () => <RfidCardDetailsSidebar />,
          },
        ],
      },
    ],
  },
  getReportingRoutes({ isPartnerCompany: true }),
  {
    path: 'partner-devices',
    component: () => <PartnerWorkspaceDevicesView />,
    children: [
      ...getDeviceDetailsSidebarRoutes({ withDeviceUuid: true }),
      {
        path: 'addGroup',
        component: () => <AddSiteGroupSidebar />,
      },
    ],
  },
];

export const privateRoutes: Route[] = [
  {
    path: '/backoffice',
    component: () => <BackofficeView />,
    children: [
      {
        path: '',
        component: () => <Navigate replace to="/backoffice/devices" />,
      },
      {
        path: 'devices',
        component: () => <BackofficeDevicesView />,
        children: getDeviceDetailsSidebarRoutes({ withDeviceUuid: true }),
      },
      {
        path: 'users',
        component: () => <BackofficeUsersView />,
      },
    ],
  },
  {
    path: '/dashboard',
    component: () => <DashboardView />,
  },
  {
    path: '/map',
    component: () => <OverviewMap />,
  },
  getSitesRoutes(),
  getSiteRoutes(),
  getDeviceRoutes(),
  getInverterRoutes(),
  getReportingRoutes(),
  {
    path: '/vehicles',
    component: () => <Vehicles />,
    children: [
      {
        path: 'menu-details/:vehicleUuid',
        component: () => <VehicleDetailsSidebar />,
      },
    ],
  },
  {
    path: '/vehicles/:vehicleUuid',
    component: () => <VehicleView />,
    children: [
      {
        path: '',
        component: () => <Navigate replace to="overview" />,
      },
      {
        path: 'overview',
        component: () => <VehicleOverview />,
        children: [
          {
            path: 'menu-details',
            component: () => <VehicleDetailsSidebar />,
          },
        ],
      },
      {
        path: 'sessions',
        component: () => <VehicleHistory />,
        children: [
          {
            path: 'menu-details',
            component: () => <VehicleDetailsSidebar />,
          },
        ],
      },
    ],
  },
  {
    path: '/access-management/',
    component: () => <WorkspaceBaseView />,
    children: [
      {
        path: '',
        component: () => <Navigate replace to="members" />,
      },
      {
        path: 'members',
        component: () => <WorkspaceMembersView />,
        children: [
          {
            path: 'invite',
            component: () => <WorkspaceInviteSidebar />,
          },
        ],
      },
      {
        path: 'rfidCards',
        component: () => <WorkspaceRfidCardsView />,
        children: [
          {
            path: 'add',
            component: () => <AddRfidCardSidebar />,
          },
          {
            path: ':rfidCardUuid',
            component: () => <RfidCardDetailsSidebar />,
          },
        ],
      },
      {
        path: 'groups',
        component: () => <WorkspaceGroupsView />,
        children: [
          {
            path: 'create',
            component: () => <CreateGroupSidebar />,
          },
          {
            path: ':groupUuid',
            component: () => <EditGroupSidebar />,
          },
        ],
      },
    ],
  },
  {
    path: '/workspace',
    component: () => <WorkspaceBaseView />,
    children: [
      {
        path: '',
        component: () => <Navigate replace to="settings" />,
      },
      {
        path: 'settings',
        component: () => <WorkspaceSettingsView />,
        children: [
          {
            path: 'companyDetails',
            component: () => <CompanyDetailsSidebarBase />,
          },
          {
            path: 'paymentMethod',
            component: () => <PaymentMethodSidebar />,
          },
          {
            path: 'payoutDetails',
            component: () => <PayoutDetailsSidebar />,
          },
          {
            path: 'companyPartnership',
            component: () => <EditCompanyPartnershipSidebar />,
          },
        ],
      },
      {
        path: 'invoices',
        component: () => <WorkspaceInvoicesView />,
      },
      {
        path: 'subscriptions',
        component: () => <WorkspaceSubscriptionsView />,
        children: [
          {
            path: 'subscribe',
            component: () => <SubscriptionsSidebar />,
            children: [
              {
                path: 'complete',
                component: () => <></>,
              },
            ],
          },
          {
            path: 'setup',
            component: () => <WorkspaceSetupSidebar />,
            children: [
              {
                path: 'paymentMethod',
                component: () => <CompanyPaymentMethod isSetup />,
              },
              {
                path: 'companyDetails',
                component: () => <CompanyDetails isSetup />,
              },
              {
                path: 'payoutDetails',
                component: () => <PayoutDetails isSetup />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/logs',
    component: () => <LogsView />,
  },
];

export const publicRoutes = (defaultPath = DEFAULT_PATH): Route[] => [
  {
    path: '/',
    component: () => <Navigate replace to={defaultPath} />,
  },
  {
    path: '/welcome',
    component: () => <Welcome />,
  },
  {
    path: '/login',
    component: () => <Login />,
  },
  {
    path: '/forgot-password',
    component: () => <ForgotPassword />,
  },
  {
    path: '/register',
    component: () => <Register />,
  },
  {
    path: '/logout',
    component: () => <Logout />,
  },
  {
    path: '/reset-password/:token',
    component: () => <ResetPassword />,
  },
];
