import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { FeatureDto, FeatureId } from 'models/subscriptions';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { CardContainer } from '@components/atoms/CardContainer';
import { StyledButton } from '@components/atoms/StyledButton';
import { ReactComponent as EllipseIcon } from '@images/icons/subscriptions/ellipse.svg';
import { ReactComponent as PublicChargingIcon } from '@images/icons/subscriptions/public-charging.svg';
import { ReactComponent as ScheduledReportsIcon } from '@images/icons/subscriptions/scheduled-reports.svg';
import { ReactComponent as ThirdPartyChargersIcon } from '@images/icons/subscriptions/third-party-chargers.svg';

import { ActiveSubscriptionDetails } from './ActiveSubscriptionDetails';
import { CancelledSubscriptionDetails } from './DeactivatedSubscriptionDetails';
import { FeatureDetailsButton } from './FeatureDetailsButton';
import { PublicChargingSubscriptionDescription } from './PublicChargingSubscriptionDescription';
import { SubscriptionPrice } from './SubscriptionPrice';

type FeatureCardProps = {
  feature: FeatureDto;
  onFeatureSelected: () => void;
};

export const FeatureCard = ({ feature, onFeatureSelected }: FeatureCardProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const hasTrial = (feature.trialPeriodDays ?? 0) > 0;
  const hasDiscount = !!feature.featureDiscount?.companyDiscount;
  const activeSubscription = !!feature.subscription?.isActive;
  const trialExpired = feature.subscription?.trialEndsAt && new Date(feature.subscription.trialEndsAt) < new Date();
  const eligibleForTrial = hasTrial && !activeSubscription && !trialExpired;

  const featureIconMap: Record<FeatureId, ReactNode> = {
    'device.public-charging': <PublicChargingIcon color={theme.palette.darkGray} />,
    'company.report-schedules': <ScheduledReportsIcon color={theme.palette.forestGreenCrayola} />,
    'device.third-party-chargers': <ThirdPartyChargersIcon color={theme.palette.darkGray} />,
  };

  const featureIconColorMap: Record<FeatureId, string> = {
    'device.public-charging': theme.palette.lightGray,
    'company.report-schedules': theme.palette.lightGreen,
    'device.third-party-chargers': theme.palette.lightGray,
  };

  return (
    <CardContainer display="flex" flexDirection={isMobile ? 'column' : 'row'} flex={1} gap={3} sx={{ padding: 4 }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexShrink={0}
        width={48}
        height={48}
        borderRadius={2}
        bgcolor={featureIconColorMap[feature.featureId]}
      >
        {featureIconMap[feature.featureId]}
      </Box>
      <Box display="flex" flexDirection="column" flex={1} gap={2}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="p16b">{feature.name}</Typography>
            {eligibleForTrial && (
              <>
                <EllipseIcon color={theme.palette.spanishGray} />
                <Typography variant="p16">
                  {t('countDaysFreeTrial', '{{count}} days free trial', { count: feature.trialPeriodDays })}
                </Typography>
              </>
            )}
          </Box>
          {feature.featureId === FeatureId.DEVICE_PUBLIC_CHARGING ? (
            <PublicChargingSubscriptionDescription />
          ) : (
            <Typography variant="p14" color={theme.palette.darkGray}>
              {feature.description}
            </Typography>
          )}
        </Box>
        {activeSubscription && (
          <>
            <FeatureDetailsButton feature={feature} />
            <ActiveSubscriptionDetails feature={feature} />
          </>
        )}
        {!activeSubscription && feature.subscription && <CancelledSubscriptionDetails feature={feature} />}
      </Box>
      <Box
        display="flex"
        flexDirection={isMobile ? 'column-reverse' : 'column'}
        alignItems={isMobile ? 'start' : 'end'}
        flexShrink={0}
        gap={2}
      >
        <StyledButton
          color={activeSubscription ? 'secondary' : 'success'}
          onClick={onFeatureSelected}
          fullWidth={isMobile}
        >
          {activeSubscription && t('cancel', 'Cancel')}
          {eligibleForTrial ? t('startTrial', 'Start trial') : !activeSubscription && t('subscribe', 'Subscribe')}
        </StyledButton>
        <SubscriptionPrice feature={feature} hasDiscount={hasDiscount} alignItems={isMobile ? 'start' : 'end'} />
      </Box>
    </CardContainer>
  );
};
