import { useTranslation } from 'react-i18next';

import { LmcMultipoint } from 'models/lmc-multipoint';
import * as yup from 'yup';

export type ChargerSettingsForm = {
  name?: string | null;
  lmcMultipoint?: { label: string; value: string | null } | null;
  communicatesViaLmc: 1 | 0;
  gridConnection: number;
  chargeEnabled: boolean;
  autoStart: boolean;
  defaultIdTagEnabled: boolean;
  defaultIdTag: string | null;
};

export const chargerSettingsFormDefaultValues: ChargerSettingsForm = {
  name: undefined,
  lmcMultipoint: undefined,
  communicatesViaLmc: 0,
  gridConnection: 0,
  chargeEnabled: false,
  autoStart: false,
  defaultIdTagEnabled: false,
  defaultIdTag: null,
};

export const useChargerSettingsSchema = ({ lmcMultipoints }: { lmcMultipoints: LmcMultipoint[] }) => {
  const { t } = useTranslation();

  return yup
    .object()
    .shape({
      deviceName: yup.string(),
      lmcMultipoint: yup
        .object()
        .shape({
          label: yup.string(),
          value: yup.string().nullable(),
        })
        .nullable(),
      communicatesViaLmc: yup.number(),
      gridConnection: yup.lazy((value, { parent }) => {
        if (value?.trim?.() === '') {
          return yup.string();
        }
        const lmcMultipointUuid = parent.lmcMultipoint?.value;
        const gridConnectionLimit =
          lmcMultipointUuid &&
          lmcMultipoints.find((lmcMultipoint) => lmcMultipoint.uuid === lmcMultipointUuid)?.currentLimit;
        return yup
          .number()
          .typeError(t('mustBeNumber', 'Must be a number'))
          .required(t('required*', 'Required*'))
          .test('positive', t('mustBePositive', 'Must be positive'), () => value > 0)
          .test(
            'max',
            t('mustNotExceedValue', 'Must not exceed {{value}}', { value: `${gridConnectionLimit}A` }),
            () => !gridConnectionLimit || value <= gridConnectionLimit,
          );
      }),
      chargeEnabled: yup.bool(),
      autoStart: yup.bool(),
      defaultIdTagEnabled: yup.bool(),
      defaultIdTag: yup
        .string()
        .when('defaultIdTagEnabled', {
          is: true,
          then: yup.string().required(t('required*', 'Required*')).nullable(),
        })
        .nullable(),
    })
    .required();
};
