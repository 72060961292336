import buildTarifEndpoints from '@services/tarif/endpoints';
import buildTransactionsEndpoints from '@services/transactions/legacy_endpoints';
import buildVehiclesEndpoints from '@services/vehicles/endpoints';

import buildCountriesEndpoints from '../countries/endpoints';
import buildDashboardEndpoints from '../dashboard/endpoints';
import { CustomEndpointBuilder } from './types';

export const buildEndpoints = (builder: CustomEndpointBuilder) => ({
  ...buildTransactionsEndpoints(builder),
  ...buildTarifEndpoints(builder),
  ...buildVehiclesEndpoints(builder),
  ...buildDashboardEndpoints(builder),
  ...buildCountriesEndpoints(builder),
});
