import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '@components/atoms/Button';
import { DiscardChangesModal } from '@components/modals/DiscardChangesModal';
import { SidebarBase } from '@components/sidebars/SidebarBase';
import { ReactComponent as CloseIcon } from '@images/icons/Close.svg';
import { ReactComponent as ArrowLeftIcon } from '@images/icons/arrow_left.svg';

export type EditingSidebarControllableBaseProps = {
  isOpen: boolean;
  onClose: () => unknown;
  title: string;
  subtitle?: string;
  onSaveValues?: () => boolean | Promise<boolean>;
  onDiscardValues?: () => unknown;
  disableCloseOnDiscard?: boolean;
  onBackClick?: () => unknown;
  isWide?: boolean;
  anyDataChanged?: boolean;
  skipDisablingSaveIfNoDataChanged?: boolean;
  updateLoading?: boolean;
  updateFailed?: boolean;
  updateFailedMessage?: string;
  containerClassName?: string;
  containerBottomPadding?: string;
  getReturnPath?: () => string | undefined;
  noActions?: boolean;
  compactButtons?: boolean;
  saveDisabled?: boolean;
  saveLabel?: string | JSX.Element;
  discardLabel?: string | JSX.Element;
  footer?: React.FC;
  isSubSidebar?: boolean;
  children: ReactNode;
};

export const EditingSidebarControllableBase = ({
  isOpen,
  onClose,
  title,
  subtitle,
  onSaveValues,
  onDiscardValues,
  disableCloseOnDiscard,
  onBackClick,
  isWide,
  anyDataChanged,
  skipDisablingSaveIfNoDataChanged,
  updateLoading,
  updateFailed,
  updateFailedMessage,
  containerClassName,
  containerBottomPadding,
  getReturnPath,
  noActions,
  compactButtons,
  saveDisabled,
  saveLabel,
  discardLabel,
  footer: Footer,
  isSubSidebar,
  children,
}: EditingSidebarControllableBaseProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);

  useEffect(() => {
    if (!isFirstRender && !isOpen) {
      const timeoutId = setTimeout(() => navigate(getReturnPath?.() ?? '..', { replace: true }), 500);
      return () => clearTimeout(timeoutId);
    }
    setIsFirstRender(false);
    return () => {};
  }, [isOpen]);

  const discard = () => {
    onDiscardValues?.();
    onClose?.();
  };

  const handleCloseSidebar = () => {
    if (anyDataChanged) {
      setIsDiscardModalOpen(true);
    } else {
      discard();
    }
  };

  const handleSaveValues = async () => {
    if (await onSaveValues?.()) {
      onClose?.();
    }
  };

  const handleDiscardValues = async () => {
    if (!disableCloseOnDiscard) {
      handleCloseSidebar();
    } else {
      onDiscardValues?.();
    }
  };

  return (
    <SidebarBase isWide={isWide} isOpen={isOpen} isSubSidebar={isSubSidebar} onClose={handleCloseSidebar}>
      <div
        role="button"
        className="relative flex h-full cursor-auto flex-col"
        onKeyDown={(e) => e.key === 'Escape' && handleCloseSidebar()}
        tabIndex={-1}
      >
        <div
          className={`flex-1 overflow-y-auto p-4 sm:px-6 sm:pt-6 ${
            containerBottomPadding ?? 'pb-24'
          } ${containerClassName}`}
        >
          <div className="mb-8 flex items-center font-everett text-2xl">
            <button
              type="button"
              className={`${!isSubSidebar && !onBackClick ? 'sm:hidden' : ''}  block h-12 w-12 rounded-full`}
              onClick={onBackClick || handleCloseSidebar}
            >
              <ArrowLeftIcon />
            </button>
            <div className="-ml-1 flex flex-1 flex-col sm:ml-0">
              <div>{title}</div>
              <div className="font-poppins text-sm font-normal">{subtitle}</div>
            </div>
            <button
              type="button"
              className={`${
                !isSubSidebar ? 'sm:flex' : ''
              } hidden h-12 w-12 items-center justify-center rounded-full sm:hover:bg-gray-200 sm:active:bg-gray-400`}
              onClick={handleCloseSidebar}
            >
              <CloseIcon />
            </button>
          </div>
          {children}
        </div>
        {!noActions && (
          <div className="absolute inset-x-0 bottom-0 z-10 border-t border-gray-200 bg-white px-4 py-2 font-poppins sm:px-6">
            {Footer && <Footer />}
            {(updateFailed || updateFailedMessage) && (
              <div className="mb-1 text-center text-sm text-red-500">
                {updateFailedMessage ?? t('savingFailed', 'Saving failed')}
              </div>
            )}
            <div
              className={`${isWide ? 'ml-auto max-w-[348px]' : ''} flex flex-grow-0 justify-end gap-2 font-semibold`}
            >
              <Button
                variant="secondary"
                className={compactButtons ? '' : 'flex-1'}
                disabled={updateLoading}
                onClick={handleDiscardValues}
              >
                {discardLabel ?? t('discard', 'Discard')}
              </Button>
              <Button
                variant="primary"
                className={compactButtons ? '' : 'flex-1'}
                disabled={updateLoading || saveDisabled || (!anyDataChanged && !skipDisablingSaveIfNoDataChanged)}
                onClick={handleSaveValues}
              >
                {saveLabel ?? t('saveChanges', 'Save changes')}
              </Button>
            </div>
          </div>
        )}
        <DiscardChangesModal
          isOpen={isDiscardModalOpen}
          onClose={() => setIsDiscardModalOpen(false)}
          onDiscard={discard}
        />
      </div>
    </SidebarBase>
  );
};
