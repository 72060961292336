import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { skipToken } from '@reduxjs/toolkit/query';
import { DeviceModalOption } from 'models/device.enums';
import { Permission } from 'models/permission.enum';

import { DeleteDeviceModal } from '@components/devices/DeleteDeviceModal';
import { TabsWithMenu } from '@components/tabs/TabsWithMenu';
import { selectPermissionByName } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';
import { useGetInverterQuery, useGetInverterSummaryQuery } from '@services/inverters/endpoints';
import { selectInverterByUuid } from '@services/inverters/selectors';
import { useGetSiteQuery } from '@services/sites/endpoints';
import { selectSiteByUuid } from '@services/sites/selectors';
import { DurationKey, durationToTimeFrame } from '@services/transactions/chartDataParser';

import { InverterRenameModal } from './InverterRenameModal';

export const SingleInverterView = () => {
  const { t } = useTranslation();
  const { inverterUuid } = useParams();
  const { pathname } = useLocation();

  const inverter = useAppSelector((state) => (inverterUuid ? selectInverterByUuid(state, inverterUuid) : undefined));
  const site = useAppSelector((state) =>
    inverter?.siteUuid ? selectSiteByUuid(state, inverter.siteUuid!) : undefined,
  );
  const canEditDevices = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_DEVICES));
  const canDeleteDevices = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_DELETE_DEVICES));

  const [selectedDuration, setSelectedDuration] = useState<DurationKey>();
  const [modalOption, setModalOption] = useState<DeviceModalOption>();

  const tabs = [
    {
      label: t('overview', 'Overview'),
      path: `/inverters/${inverter?.uuid}/overview`,
    },
    {
      label: t('historicalData', 'Historical data'),
      path: `/inverters/${inverter?.uuid}/sessions`,
    },
  ];
  const activeTab = tabs.find((tab) => pathname.includes(tab.path)) ?? tabs[0];

  const menuItems = [
    {
      title: t('renameSolarInverter', 'Rename solar inverter'),
      key: 'renameSolarInverter',
      onClick: () => setModalOption(DeviceModalOption.RENAME),
      hidden: !canEditDevices,
    },
    {
      title: t('deleteDevice', 'Delete device'),
      key: 'delete-device',
      className: 'text-vermillion',
      onClick: () => setModalOption(DeviceModalOption.DELETE),
      hidden: !canDeleteDevices,
    },
  ];

  const timeFrame = selectedDuration && durationToTimeFrame[selectedDuration];

  useGetInverterQuery(inverterUuid ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  useGetSiteQuery(inverter?.siteUuid ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const { isFetching: isFetchingInverterSummary } = useGetInverterSummaryQuery(
    inverterUuid
      ? {
          uuid: inverterUuid,
          duration: selectedDuration,
        }
      : skipToken,
    {
      skip: !selectedDuration,
      refetchOnMountOrArgChange: true,
    },
  );

  return (
    <>
      <TabsWithMenu activeTab={activeTab} tabs={tabs} menuItems={menuItems} />
      {inverter && (
        <>
          <DeleteDeviceModal
            isOpen={modalOption === DeviceModalOption.DELETE}
            closeModal={() => setModalOption(undefined)}
            device={inverter}
          />
          <InverterRenameModal
            inverter={inverter}
            isOpen={modalOption === DeviceModalOption.RENAME}
            closeModal={() => setModalOption(undefined)}
          />
        </>
      )}
      <Outlet
        context={{
          inverter,
          site,
          selectedDuration,
          setSelectedDuration,
          timeFrame,
          isFetchingInverterSummary,
        }}
      />
    </>
  );
};
