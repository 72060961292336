import { useTranslation } from 'react-i18next';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { ReactComponent as HelpIcon } from '@images/icons/subscriptions/help.svg';

export const WorkspaceSubscriptionsViewHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Title />
          <HelpButton />
        </Box>
        <PricesInfo />
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Title />
      <Box display="flex" gap={5}>
        <PricesInfo />
        <HelpButton />
      </Box>
    </Box>
  );
};

const Title = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Typography variant="p18" color={theme.palette.darkGray}>
      {t('subscriptions', 'Subscriptions')}
    </Typography>
  );
};

const HelpButton = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Typography
      variant="p14"
      component="button"
      display="flex"
      alignItems="center"
      color={theme.palette.darkGray}
      gap={0.5}
      onClick={() => window.open('https://support.vool.com/en/collections/10506185-subscriptions', '_blank')}
    >
      {t('help', 'Help')}
      <HelpIcon />
    </Typography>
  );
};

const PricesInfo = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Typography variant="p14" color={theme.palette.darkGray}>
      {t('allPricesExcludeVat', 'All prices exclude VAT')}
    </Typography>
  );
};
