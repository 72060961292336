import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';

import { Permission } from 'models/permission.enum';

import { Box, Divider, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';

import { MenuContainer } from '@components/atoms/MenuContainer';
import { StyledButton } from '@components/atoms/StyledButton';
import { StyledMenuItem } from '@components/atoms/StyledMenuItem';
import { Avatar } from '@components/avatar/Avatar';
import { PartnerWorkspaceContext } from '@core/WorkspaceGuard';
import { useSwitchToCompany } from '@hooks/useSwitchToCompany';
import { ReactComponent as CloseIcon } from '@images/icons/Close.svg';
import { ReactComponent as MenuArrowIcon } from '@images/icons/MenuArrow.svg';
import { ReactComponent as PlusIcon } from '@images/icons/Plus.svg';
import { ReactComponent as CheckImage } from '@images/icons/check.svg';
import { ReactComponent as SettingsIcon } from '@images/icons/reporting/settings.svg';
import { isSubdomainPartner } from '@routesConfig/routesUtil';
import { selectPermissionByName, selectUser } from '@services/auth/selectors';
import { useGetCompaniesQuery, useLazyGetCompanyUserPermissionsQuery } from '@services/companies/endpoints';
import { selectActiveCompany, selectCompanies, selectPartnerCompanies } from '@services/companies/selectors';
import { useAppSelector } from '@services/hooks';
import { CreateWorkspaceModal } from '@views/Workspace/CreateWorkspaceModal';

import { UserSettingsModal } from './UserSettingsModal';

export const UserProfileMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const user = useAppSelector(selectUser);
  const allCompanies = useAppSelector(selectCompanies);
  const partnerCompanies = useAppSelector(selectPartnerCompanies);
  const userActiveCompany = useAppSelector(selectActiveCompany);
  const canEditCompany = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_COMPANY));

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [isUserSettingsModalOpen, setIsUserSettingsModalOpen] = useState(false);
  const [isCreateWorkspaceModalOpen, setIsCreateWorkspaceModalOpen] = useState(false);

  const { activePartnerCompanyUuid, setActivePartnerCompanyUuid } = useContext(PartnerWorkspaceContext);

  useGetCompaniesQuery();
  const switchToCompany = useSwitchToCompany();
  const [getCompanyUserPermissionQuery] = useLazyGetCompanyUserPermissionsQuery();

  const companies = isSubdomainPartner() ? partnerCompanies : allCompanies;

  const activeCompany = useMemo(() => {
    return isSubdomainPartner()
      ? partnerCompanies.find((company) => company.uuid === activePartnerCompanyUuid)
      : userActiveCompany;
  }, [activePartnerCompanyUuid, userActiveCompany, companies]);

  useEffect(() => {
    if (isSubdomainPartner() && !activePartnerCompanyUuid && companies.length > 0) {
      setActivePartnerCompanyUuid(companies[0].uuid);
    }
  }, [activePartnerCompanyUuid, companies, setActivePartnerCompanyUuid]);

  useEffect(() => {
    if (activeCompany) {
      getCompanyUserPermissionQuery(activeCompany.uuid);
    }
  }, [activeCompany]);

  if (!user) {
    return null;
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        sx={{
          cursor: 'pointer',
        }}
        minWidth={150}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {activeCompany ? (
          <>
            <Avatar uuid={activeCompany.uuid} name={activeCompany.name} />
            <Box display="flex" flexDirection="column" overflow="hidden">
              <Typography variant="p14" noWrap>
                {activeCompany.name ?? t('workspace', 'Workspace')}
              </Typography>
              <Typography variant="p12" color="#999898" noWrap>
                {user.name || user.email}
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Avatar name={user.name} email={user.email} />
            <Box display="flex" flexDirection="column" overflow="hidden">
              <Typography variant="p14" noWrap>
                {user.name || user.email}
              </Typography>
            </Box>
          </>
        )}
        <MenuArrowIcon className="min-w-fit transform transition duration-300 ease-in-out group-hover:translate-y-0.5" />
      </Box>
      <MenuContainer
        horizontalOrigin="right"
        width={isMobile ? '100%' : '400px'}
        isMobile={isMobile}
        anchorEl={anchorEl}
        paperProps={{
          sx: {
            p: 0,
          },
        }}
        onClose={() => setAnchorEl(undefined)}
      >
        <Box display="flex" flexDirection="column" gap={4} mt={isMobile ? 2 : 5} height={1}>
          <IconButton
            onClick={() => setAnchorEl(undefined)}
            sx={{
              position: 'absolute',
              top: 16,
              right: 16,
              color: 'black',
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            display="flex"
            flexDirection="column"
            alignItems={isMobile ? 'left' : 'center'}
            gap={1}
            px={isMobile ? 2 : 1}
          >
            <Avatar name={user.name} email={user.email} bigger />
            <Typography variant="h3" textAlign={isMobile ? 'left' : 'center'}>
              {user.name}
            </Typography>
            <Typography variant="p14" textAlign={isMobile ? 'left' : 'center'}>
              {user.email}
            </Typography>
            <Box display="flex" gap={1}>
              <StyledButton
                color="info"
                onClick={() => {
                  setAnchorEl(undefined);
                  setIsUserSettingsModalOpen(true);
                }}
              >
                {t('settings', 'Settings')}
              </StyledButton>
              <StyledButton color="info" onClick={() => navigate('/logout')}>
                {t('logOut', 'Log out')}
              </StyledButton>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" height={1}>
            <Divider />
            {companies.length < 5 ? (
              companies.map((company) => (
                <Fragment key={company.uuid}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    py={0.5}
                    pl={1.5}
                    pr={2}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#F4F4F4',
                      },
                    }}
                  >
                    <StyledMenuItem
                      label={company.name ?? t('workspace', 'Workspace')}
                      Icon={() => (
                        <Avatar
                          uuid={company.uuid}
                          name={company.name ?? t('workspace', 'Workspace')}
                          backgroundColor="#FBDCD2"
                        />
                      )}
                      width="100%"
                      disabled={company.uuid === activeCompany?.uuid}
                      sx={{
                        color: 'black',
                      }}
                      onClick={() => {
                        switchToCompany(company);
                        setAnchorEl(undefined);
                      }}
                    />
                    {company.uuid === activeCompany?.uuid && <CheckImage className="h-6 w-6" />}
                  </Box>
                  <Divider />
                </Fragment>
              ))
            ) : (
              <Virtuoso
                style={{ height: isMobile ? '100%' : '245px' }}
                totalCount={companies.length}
                itemContent={(index) => {
                  const company = companies[index];
                  return (
                    <Fragment key={company.uuid}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        py={0.5}
                        pl={1.5}
                        pr={2}
                        sx={{
                          '&:hover': {
                            backgroundColor: company.uuid !== activeCompany?.uuid ? '#F4F4F4' : '',
                          },
                        }}
                      >
                        <StyledMenuItem
                          label={company.name ?? t('workspace', 'Workspace')}
                          Icon={() => (
                            <Avatar
                              uuid={company.uuid}
                              name={company.name ?? t('workspace', 'Workspace')}
                              backgroundColor="#FBDCD2"
                            />
                          )}
                          width="100%"
                          disabled={company.uuid === activeCompany?.uuid}
                          sx={{
                            color: 'black',
                          }}
                          onClick={() => {
                            switchToCompany(company);
                            setAnchorEl(undefined);
                          }}
                        />
                        {company.uuid === activeCompany?.uuid && <CheckImage className="h-6 w-6" />}
                      </Box>
                      <Divider />
                    </Fragment>
                  );
                }}
              />
            )}
            {!isSubdomainPartner() && canEditCompany && (
              <>
                <StyledMenuItem
                  label={t('workspaceSettings', 'Workspace settings')}
                  width="100%"
                  height="48px"
                  Icon={() => (
                    <Box
                      borderRadius="100%"
                      color="white"
                      bgcolor="#386957"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      width={32}
                      height={32}
                      ml={1.5}
                    >
                      <SettingsIcon />
                    </Box>
                  )}
                  onClick={() => {
                    setAnchorEl(undefined);
                    navigate('/workspace/settings');
                  }}
                />
                <Divider />
              </>
            )}
            <StyledMenuItem
              label={t('createNewWorkspace', 'Create new workspace')}
              width="100%"
              height="48px"
              Icon={() => (
                <Box
                  borderRadius="100%"
                  color="white"
                  bgcolor="#386957"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width={32}
                  height={32}
                  ml={1.5}
                >
                  <PlusIcon />
                </Box>
              )}
              onClick={() => {
                setAnchorEl(undefined);
                setIsCreateWorkspaceModalOpen(true);
              }}
            />
          </Box>
        </Box>
      </MenuContainer>
      <UserSettingsModal isOpen={isUserSettingsModalOpen} closeModal={() => setIsUserSettingsModalOpen(false)} />
      <CreateWorkspaceModal isOpen={isCreateWorkspaceModalOpen} onClose={() => setIsCreateWorkspaceModalOpen(false)} />
    </>
  );
};
