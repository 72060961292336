import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { PaymentMethod } from 'models/paymentMethod';
import { StripePaymentMethodType } from 'models/stripe.enums';

import { Box } from '@mui/material';

import { DeleteModal } from '@components/modals/DeleteModal';
import ArrowDown from '@images/icons/ArrowDown.svg';
import Trash from '@images/icons/Trash.svg';
import Check from '@images/icons/check.svg';
import { useDeletePaymentMethodMutation } from '@services/billing/endpoints';
import { addToastMessage } from '@services/toastMessages';

import { PaymentMethodItemCard } from './PaymentMethodItemCard';
import { PaymentMethodItemSepa } from './PaymentMethodItemSepa';

type PaymentMethodItemProps = {
  paymentMethod: PaymentMethod;
  onPaymentMethodDeleted?: () => void;
  checkIcon?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  preventActions?: boolean;
};

export const PaymentMethodItem = ({
  paymentMethod,
  onPaymentMethodDeleted,
  checkIcon,
  isSelected,
  onClick,
  preventActions,
}: PaymentMethodItemProps) => {
  const { card } = paymentMethod;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletePaymentMethod, { isLoading: isDeleteLoading }] = useDeletePaymentMethodMutation();

  const handleDelete = async (id: string) => {
    try {
      await deletePaymentMethod(id).unwrap();
      dispatch(addToastMessage({ type: 'success', title: t('paymentMethodDeleted', 'Payment method deleted') }));
      onPaymentMethodDeleted?.();
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('deletePaymentMethodFailed', 'Deleting payment method failed'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
    }
  };

  return (
    <Box
      className={`flex w-full cursor-pointer items-center gap-4 border-b border-zinc-100 p-2 text-sm last:border-b-0`}
      onClick={onClick}
    >
      {paymentMethod.type === StripePaymentMethodType.SEPA_DEBIT ? (
        <PaymentMethodItemSepa />
      ) : (
        <PaymentMethodItemCard {...paymentMethod} />
      )}
      <div className="flex gap-4 p-1">
        {isSelected && <img src={ArrowDown} alt="Arrow down icon" />}
        {checkIcon && <img src={Check} alt="Check icon" />}
        {!isSelected && (
          <Box
            className="flex"
            onClick={(e) => {
              e.stopPropagation();
              if (!isDeleteLoading && !preventActions && !isSelected) {
                setDeleteModalOpen(true);
              }
            }}
          >
            <img src={Trash} alt="Trash icon" />
          </Box>
        )}
      </div>
      <DeleteModal
        title={t('deleteCard', 'Delete card')}
        confirmText={t('yesDeleteCard', 'Yes, delete the card')}
        isOpen={deleteModalOpen}
        onDelete={(e) => {
          e.stopPropagation();
          setDeleteModalOpen(false);
          handleDelete(paymentMethod.id);
        }}
        onClose={(e) => {
          e.stopPropagation();
          setDeleteModalOpen(false);
        }}
      >
        <div>
          {t('deleteCardConfirmation', 'Are you sure you want to delete the card')}
          {paymentMethod.type === StripePaymentMethodType.CARD && (
            <span className="font-semibold"> **** {card.last4}</span>
          )}
          ?
        </div>
      </DeleteModal>
    </Box>
  );
};
