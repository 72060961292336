import { useTranslation } from 'react-i18next';

import { FeatureDto } from 'models/subscriptions';
import moment from 'moment';

import { Box, Divider, Typography, useTheme } from '@mui/material';

import { useSubscriptionItemCount } from './useSubscriptionItemCount';

type ActiveSubscriptionDetailsProps = {
  feature: FeatureDto;
};

export const ActiveSubscriptionDetails = ({ feature }: ActiveSubscriptionDetailsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { count } = useSubscriptionItemCount(feature);

  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Box display="flex" gap={0.5}>
        <Typography variant="p14">{t('estimatedMonthlyCost', 'Estimated monthly cost')}:</Typography>
        <Typography variant="p14b">
          {`${(
            count * Number(feature.featurePrice.price * (1 - (feature.featureDiscount?.discountPercentage ?? 0)))
          ).toFixed(2)} €`}
        </Typography>
      </Box>
      <Divider sx={{ borderBottom: `1px solid ${theme.palette.cultured}` }} />
      <Box display="flex" gap={0.5}>
        <Typography variant="p14">{t('activatedBy', 'Activated by')}:</Typography>
        <Typography variant="p14b">{feature.subscription?.activatedByUser}</Typography>
      </Box>
      <Divider sx={{ borderBottom: `1px solid ${theme.palette.cultured}` }} />
      <Box display="flex" gap={0.5}>
        <Typography variant="p14">{t('activeSince', 'Active since')}:</Typography>
        <Typography variant="p14b">
          {feature.subscription ? moment(feature.subscription.activatedAt).format('DD.MM.YYYY') : 'N/A'}
        </Typography>
      </Box>
      {feature.subscription?.trialEndsAt && new Date(feature.subscription.trialEndsAt) > new Date() && (
        <>
          <Divider sx={{ borderBottom: `1px solid ${theme.palette.cultured}` }} />
          <Box display="flex" gap={0.5}>
            <Typography variant="p14">{t('trialEnding', 'Trial ending')}:</Typography>
            <Typography variant="p14b" color={theme.palette.mediumVermilion}>
              {feature.subscription?.trialEndsAt
                ? moment(feature.subscription.trialEndsAt).format('DD.MM.YYYY')
                : 'N/A'}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};
