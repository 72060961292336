import { Skeleton, TextField, TextFieldProps, styled } from '@mui/material';

export const BaseStyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: '48px',
    '&:hover fieldset': {
      borderColor: '#999898',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#D6D6D6',
    },
    '&.Mui-disabled fieldset': {
      borderColor: '#F4F4F4',
    },
    '&.Mui-error fieldset': {
      color: '#EB4E20',
      borderColor: '#EB4E20',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: '2px',
    borderRadius: '8px',
    borderColor: '#D6D6D6',
  },
  '& .MuiInputLabel-shrink': {
    color: '#999898',
    transform: 'translate(14px, -16px) !important',
  },
  '& .MuiInputLabel-root': {
    transform: 'translate(14px, 12px)',
    '&.Mui-focused': {
      color: '#5E5E5E',
    },
    '&.Mui-disabled': {
      color: '#D6D6D6',
    },
    '&.Mui-error': {
      color: '#EB4E20',
    },
  },
  '& .MuiFormHelperText-root': {
    color: '#5E5E5E',
    '&.Mui-error': {
      color: '#EB4E20',
    },
  },
});

export type StyledTextFieldProps = TextFieldProps & {
  isLoading?: boolean;
  isInteger?: boolean;
};

export const StyledTextField = ({ isLoading, isInteger, ...props }: StyledTextFieldProps) => {
  return isLoading ? (
    <Skeleton component="input" variant="rounded" animation="wave" width="100%" height={48} {...props.inputProps} />
  ) : (
    <BaseStyledTextField
      {...props}
      onKeyDown={(e) => {
        if (isInteger && ['.', ',', 'e'].includes(e.key)) {
          e.preventDefault();
        }
      }}
    />
  );
};
